import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlueButtonComponent } from './blue-button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [BlueButtonComponent],
    exports: [BlueButtonComponent],
})
export class BlueButtonModule {}
