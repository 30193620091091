import { JobType } from '../enum/job-title.enum';

export class SharedDocument {
    public id: number;
    public name: string;
    public originalName: string;
    public folderId: number;
    public path: string;
    public contentType: string;
    public oneTimeOpenPermission: JobType[];
    public alreadyOpened: boolean;
    public oneTimeOpenUser: boolean;
}
