import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Coworker } from '../../model/coworker.model';
import { CoworkerService } from 'src/app/service/coworker.service';
import { MatDialog } from '@angular/material/dialog';
import { CoworkerAdditionComponent } from './coworker-addition/coworker-addition.component';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { Subscription } from 'rxjs';
import { environment } from '@app/../environments/environment';
import Pageable from '@app/model/pageable';
import { ScrollService } from '@app/service/scroll.service';
import { Role } from '@app/enum/role.enum';

export const COWORKER_CARD_HEIGHT = 170;

@Component({
    selector: 'app-coworkers',
    templateUrl: './coworkers.component.html',
    styleUrls: ['./coworkers.component.scss'],
})
export class CoworkersComponent implements OnInit, OnDestroy, DoCheck {
    selectedCoworker: Coworker | null = null;
    coworkers: Coworker[];
    pageableCoworkers: Pageable<Coworker>;
    displayedCoworkers: Coworker[];
    page: number = 1;
    totalItems: number;
    previousTotalItems: number;
    isSearching: boolean = false;
    searchTerm: string | undefined;
    activatedFilter: boolean = true;
    selectedTabValue: Role = Role.ENGINEER;
    private coworkerDeletionSub: Subscription;
    private scrollSubscription: Subscription;
    roleMapping: { [key: string]: Role } = {
        PM: Role.PROJECT_MANAGER,
        HR: Role.HR,
        Admin: Role.ADMIN,
        CLIENT: Role.CLIENT,
        ENGINEER: Role.ENGINEER,
        QA: Role.QA,
    };
    searchActive: boolean = false;
    delayTimer: any;

    constructor(
        private route: ActivatedRoute,
        private coworkerService: CoworkerService,
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        public tokenStorageService: TokenStorageService,
        private scrollService: ScrollService,
    ) {}

    ngOnInit(): void {
        this.pageableCoworkers = this.route.snapshot.data.activeCoworkers;
        this.totalItems = this.pageableCoworkers.totalItems;
        this.previousTotalItems = this.totalItems;
        this.scrollSubscription = this.scrollService
            .isScrolledToBottom(COWORKER_CARD_HEIGHT / 2)
            .subscribe((isBottom) => {
                if (isBottom && this.coworkers.length < this.totalItems) {
                    this.page++;
                    this.coworkerService
                        .getCoworkersByActivatedPage(this.activatedFilter, this.page, this.selectedTabValue)
                        .subscribe((coworkersResponse: Pageable<Coworker>) => {
                            this.coworkers.push(...coworkersResponse.items);
                            const newTotalItems = coworkersResponse.totalItems - this.previousTotalItems;
                            this.totalItems = newTotalItems > 0 ? newTotalItems : 0;
                        });
                }
            });
        this.coworkers = this.pageableCoworkers.items;
        this.filterCoworkersBySearchTerm();
        this.loadProfilePic();
        this.coworkerDeletionSub = this.coworkerService.coworkerDelete.subscribe(
            (id: number) =>
                (this.displayedCoworkers = this.displayedCoworkers.filter((coworker) => coworker.id !== id)),
        );
    }

    ngDoCheck() {
        if (!this.tokenStorageService.isLoggedIn()) {
            this.dialog.closeAll();
        }
    }

    ngOnDestroy(): void {
        this.coworkerDeletionSub.unsubscribe();
        this.scrollSubscription.unsubscribe();
    }

    onSelectedChange(coworker: Coworker) {
        this.selectedCoworker = coworker;
        this.openCoworkerAdditionDialog(coworker);
    }

    loadProfilePic() {
        // Append a timestamp to the URL to force browser to reload the image.
        this.displayedCoworkers.forEach((coworker) => {
            coworker.profilePicturePath = `${environment.serverUrl}/user/profile/${
                coworker.id
            }?${new Date().getTime()}`;
        });
    }

    loadCoworkers(): void {
        this.coworkerService
            .getCoworkersByActivatedPage(this.activatedFilter, 1, this.selectedTabValue)
            .subscribe((resp) => {
                this.page = 1;
                this.totalItems = resp.totalItems;
                this.coworkers = resp.items;
                this.filterCoworkersBySearchTerm();
                this.loadProfilePic();
                this.changeDetector.detectChanges();
            });
    }

    setActivatedFilter(button) {
        this.activatedFilter = button.id === 'ACTIVE';

        this.loadCoworkers();
    }

    openCoworkerAdditionDialog(coworker?: Coworker) {
        return this.dialog
            .open(CoworkerAdditionComponent, {
                backdropClass: 'dialog-background',
                data: coworker,
                panelClass: 'dialog-panel',
                width: '80%',
            })
            .afterClosed()
            .subscribe((resp) => resp && this.loadCoworkers());
    }

    searchCoworkerByName(nameSubString?: string): void {
        if (nameSubString.length < 3 && nameSubString.length != 0) {
            return;
        }

        clearTimeout(this.delayTimer);

        this.delayTimer = setTimeout(() => {
            this.searchTerm = nameSubString;
            this.filterCoworkersBySearchTerm();
            this.searchActive = !!nameSubString;
        }, 500);
    }

    filterCoworkersBySearchTerm(): void {
        this.isSearching = true;
        if (!this.searchTerm || this.searchTerm === '') {
            this.displayedCoworkers = this.coworkers;
            this.isSearching = false;
        } else {
            this.coworkerService.getCoworkersBySearchTerm(this.activatedFilter, this.searchTerm).subscribe((resp) => {
                resp = this.reloadProfilePictures(resp);
                this.displayedCoworkers = resp;
                this.isSearching = false;
            });
        }
    }

    onTabSelectionChange(selectedTabValue: string): void {
        this.selectedTabValue = this.roleMapping[selectedTabValue] || Role.ENGINEER;
        this.coworkerService
            .getCoworkersByActivatedPage(this.activatedFilter, 1, this.selectedTabValue)
            .subscribe((resp) => {
                this.page = 1;
                this.totalItems = resp.totalItems;
                this.coworkers = resp.items;
                this.filterCoworkersBySearchTerm();
                this.loadProfilePic();
                this.changeDetector.detectChanges();
            });
    }

    reloadProfilePictures(resp: Coworker[]): Coworker[] {
        for (const displayedCoworker of this.displayedCoworkers) {
            for (const coworker of resp) {
                if (displayedCoworker.id === coworker.id) {
                    coworker.profilePicturePath = displayedCoworker.profilePicturePath;
                }
            }
        }

        return resp;
    }
}
