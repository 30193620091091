<div class="container">
    <div class="row">
        <div class="col-2">
            <mat-icon class="align-self-center" [ngClass]="color">{{ getAlertIcon() }}</mat-icon>
        </div>
        <div class="col-10">
            <h1 mat-dialog-title [ngClass]="color">{{ data.title }}</h1>
            <mat-dialog-content>
                <div>
                    {{ data.message }}
                </div>
            </mat-dialog-content>
            <div mat-dialog-actions class="d-flex justify-content-end">
                <button mat-button mat-dialog-close [ngClass]="bgColor">OK</button>
            </div>
        </div>
    </div>
</div>
