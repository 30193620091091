import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-input',
    templateUrl: 'input.component.html',
    styleUrls: ['input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor {
    @Output() clearFieldEvent = new EventEmitter<any>();
    @Input() formControlName: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() hasError = false;
    @Input() errorMessage = '';
    @Input() type: string = 'search';
    @Input() max: number;
    @Input() min: number;
    @Input() step: number = 0;
    @Input() removeSuffix: boolean = false;
    @Input() required: boolean = false;
    @Input() maxLength: number = 255;
    @Input() isDisabled: boolean = false;
    inputValue = '';
    @Input() visible: boolean = true;
    changeType: boolean = true;

    writeValue(value: any) {
        this.inputValue = value;
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touched function
    }

    valueChange(event: Event) {
        this.inputValue = (<HTMLInputElement>event.target).value;
        if (this.propagateChange) {
            this.propagateChange(this.inputValue);
        }
    }

    removeValue() {
        this.clearFieldEvent.emit();
    }

    viewPass() {
        this.visible = !this.visible;
        this.changeType = !this.changeType;
    }
}
