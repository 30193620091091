import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { maxDuration, minDuration } from '@validator/duration-validator';
import { ApplicationProperties } from '../model/application-properties.model';
import { Coworker } from '../model/coworker.model';
import { Folder } from '../model/folder.model';
import { SharedDocument } from '../model/shared-document.model';
import { Project } from 'src/app/model/project.model';
import {
    EmailFormatValidator,
    hasLengthValidator,
    NotDefaultValueValidator,
    RequiredIf,
    SmallerThanOrEqual,
} from 'src/helper/validators';
import { WorklogFormData } from '../model/worklog-formdata.model';

@Injectable({
    providedIn: 'root',
})
export class FormBuilderService {
    constructor(private formBuilder: UntypedFormBuilder) {}

    createProjectForm(project: Project): UntypedFormGroup {
        return this.formBuilder.group({
            // clientId: [project.clientId, [Validators.required]],
            name: [project.name, [hasLengthValidator()]],
            fte: [project.fte, [Validators.pattern(`^[\\d]+(\\.[\\d]{1,2})?$`)]],
            // sprintWeeks: [project.sprintWeeks, [Validators.pattern('^[\\d]+$')]],
            // sprintFirstDate: [project.sprintFirstDate, [Validators.required]],
            // paymentPeriod: [project.paymentPeriod, [Validators.required]],
            // accountingFirstDate: [project.accountingFirstDate, [Validators.required]],
            activated: [project.activated],
            paidQA: [project.paidQA],
            // currency: [project.currency, [Validators.required]],
            // discount: [project.discount, [Validators.pattern('^((0)|(100)|[123456789]{1}[0123456789]{0,1}){0,1}$')]],
            // language: [project.language, [Validators.required]],
            // hourlyRate: [project.hourlyRate, [Validators.pattern('^[\\d]+$')]]
        });
    }

    createCoworkerForm(coworker: Coworker): UntypedFormGroup {
        const remainingHolidaysValidators = [Validators.required, NotDefaultValueValidator()];

        if (coworker.totalHolidays != null) {
            remainingHolidaysValidators.push(SmallerThanOrEqual('totalHolidays'));
        }
        return this.formBuilder.group({
            name: [coworker.name, [Validators.pattern(/[\p{L}.\-\s']/gu)]],
            email: [coworker.email, [Validators.required, EmailFormatValidator()]],
            active: [coworker.activated],
            fte: [coworker.fte, [Validators.pattern('(^0(\\.[\\d]{1,2})?)$|(^1(\\.(0){1,2})?)$')]],
            role: [coworker.role, [Validators.required, NotDefaultValueValidator()]],
            totalHolidays: [
                coworker.totalHolidays,
                [Validators.required, NotDefaultValueValidator(), Validators.min(1)],
            ],
            remainingHolidays: [coworker.remainingHolidays, remainingHolidaysValidators],
            intern: [coworker.intern],
            calendarColor: [coworker.calendarColor],
        });
    }

    createFolderForm(folder: Folder): UntypedFormGroup {
        return this.formBuilder.group({
            id: [folder.id],
            name: [folder.name, [hasLengthValidator()]],
            folderPermissions: [folder.folderPermissions],
        });
    }

    createSharedDocumentForm(document: SharedDocument): UntypedFormGroup {
        return this.formBuilder.group({
            id: [document.id],
            folderId: [document.folderId, [Validators.required, Validators.min(1)]],
            name: [document.name, [Validators.required, Validators.min(2), Validators.max(40)]],
            oneTimeOpenPermission: [document.oneTimeOpenPermission],
        });
    }

    createFteForm(fte: number, isReviewer: boolean): UntypedFormGroup {
        return this.formBuilder.group({
            fte: [
                fte,
                [
                    RequiredIf('isReviewer', false),
                    Validators.min(0),
                    Validators.max(fte),
                    Validators.pattern('(^0(\\.[\\d]{1,2})?)$|(^1(\\.(0){1,2})?)$'),
                ],
            ],
            isReviewer: [isReviewer, [Validators.required]],
        });
    }

    createLanguageForm(language: string): UntypedFormGroup {
        return this.formBuilder.group({
            language: [language],
        });
    }

    createPropertiesForm(properties: ApplicationProperties): UntypedFormGroup {
        return this.formBuilder.group({
            invoiceEmail: [properties.invoiceEmail, Validators.email],
            employmentEmail: [properties.employmentEmail, Validators.email],
        });
    }

    createWorklogForm(worklog: WorklogFormData): UntypedFormGroup {
        return this.formBuilder.group({
            duration: [worklog.duration, [Validators.required, minDuration('00:01'), maxDuration('12:00')]],
            project: [worklog.project?.id, [Validators.required]],
            task: [worklog.task, [hasLengthValidator()]],
            description: [worklog.description, [hasLengthValidator()]],
        });
    }

    createPMWorklogForm(worklog: WorklogFormData): UntypedFormGroup {
        return this.formBuilder.group({
            duration: [worklog.duration, [Validators.required, minDuration('00:01'), maxDuration('12:00')]],
            project: [worklog.project?.id],
            task: [worklog.task, [hasLengthValidator()]],
            description: [worklog.description, [hasLengthValidator()]],
        });
    }
}
