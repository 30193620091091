import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() defaultChecked = false;
    @Input() value = true;
    @Input() disabled = false;
    @Output() onCheckboxChange: EventEmitter<any> = new EventEmitter();
    checked = false;

    ngOnInit() {
        this.checked = this.defaultChecked;
        this.checked = this.value;
    }

    ngOnChanges() {
        this.checked = this.value;
    }

    valueChange({ checked }: MatCheckboxChange) {
        this.checked = checked;
        this.onCheckboxChange.emit(checked);
    }
}
