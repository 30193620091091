import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    rabitLogo: boolean = false;
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    setLoading(loading: boolean) {
        this.loadingSubject.next(loading);
    }

    getLoading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    setToRabitLogo() {
        this.rabitLogo = true;
    }
}
