import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarConfirmationComponent } from './snackbar-confirmation.component';
import { BlueButtonModule } from '../button/blue-button/blue-button.module';
import { StrokedButtonModule } from '../button/stroked-button/stroked-button.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [BlueButtonModule, CommonModule, MaterialModule, StrokedButtonModule],
    exports: [SnackbarConfirmationComponent],
    declarations: [SnackbarConfirmationComponent],
})
export class SnackbarConfirmationModule {}
