import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app/service/loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private loaderService: LoaderService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.totalRequests++;
        //console.debug('LoadingInterceptor totalRequest='+this.totalRequests);
        this.loaderService.setLoading(true);
        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;

                if (this.totalRequests == 0) {
                    this.loaderService.rabitLogo = false;
                    this.loaderService.setLoading(false);
                }
            }),
        );
    }
}
