import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, TranslateModule, MatSelectModule, FormsModule, ReactiveFormsModule],
    declarations: [SelectComponent],
    exports: [SelectComponent],
})
export class SelectModule {}
