import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-searchbar',
    templateUrl: 'searchbar.component.html',
    styleUrls: ['searchbar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchbarComponent),
            multi: true,
        },
    ],
})
export class SearchbarComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    @Output() clearFieldEvent = new EventEmitter<any>();
    @Input() formControlName: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() required = false;
    @Input() hasError = false;
    @Input() errorMessage = '';
    @Input() type: string = 'search';
    @Input() removeSuffix: boolean = false;
    @Input() maxLength: number = 50;
    // Bindings for search logic
    @Input() resultValues: string[] = [];
    @Input() onSearch: (nameSubString?: string) => void;
    @Input() onInputChange: (nameSubString?: string) => void; // Should update resultValues

    public disabled: boolean = false;
    inputValue = '';
    showResults = false;
    searchForm: FormGroup = new FormGroup({
        searchString: new FormControl(''),
    });

    resultHideListener = (e) => {
        // Listens for clicks on page for hiding the dynamic results
        if (e.target.classList.contains('dontHideResults')) {
            this.resultValues?.length > 0 && (this.showResults = true);
        } else {
            this.showResults = false;
        }
    };

    constructor(public translateService: TranslateService) {}

    ngOnInit() {
        this.searchForm.controls['searchString'].valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((newValue) => {
                this.onInputChange(newValue);
            });

        document.addEventListener('click', this.resultHideListener);
    }

    // Listens for changes in the bound results, calculates if results should be shown
    ngOnChanges(changes: SimpleChanges) {
        if ('resultValues' in changes) {
            const resultValuesChange = changes['resultValues'];

            if (resultValuesChange?.previousValue?.length !== resultValuesChange?.currentValue?.length) {
                this.showResults = this.resultValues?.length > 0;
            }
        }
    }

    ngOnDestroy() {
        this.resultHideListener && document.removeEventListener('click', this.resultHideListener);
    }

    handleSearch(searchForResult?: string) {
        this.onSearch(searchForResult ? searchForResult : this.searchForm.controls['searchString'].value);
        this.showResults = false;
    }

    writeValue(value: any) {
        this.inputValue = value;
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touched function
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
