<div
    class="coworker-card flex-container"
    [class.activeColor]="coworker.pending === false"
    [class.inactiveColor]="coworker.pending"
>
    <div class="left">
        <div class="img-div">
            <div
                class="profile-icon mt-1"
                [style.background-image]="
                    'url(' + coworker.profilePicturePath + ') , url(' + defaultProfilePicPath + ')'
                "
            ></div>

            <!-- TODO: editing e-number will be implemented in a later FAse -->
            <div class="e-number" *ngIf="false">
                <div class="e">E</div>
                <div class="inner-div">
                    <span class="number">{{ coworker.enumber }}</span>
                </div>
            </div>
        </div>
        <div class="name" title="{{ coworker.name }}">
            {{ coworker.name | truncate: COWORKER_MAX_LENGTH }}
        </div>
    </div>

    <div class="divider"></div>

    <div class="right">
        <div class="edit flex-row justify-content-between">
            <div class="fte">{{ RoleTranslationKeys[coworker.role] | translate }}</div>
            <img class="edit-btn" src="assets/icons/light-edit.svg" (click)="onClick()" />
        </div>
        <div class="fte" *ngIf="coworker.fte">{{ coworker.fte }} {{ 'coworkers.fte' | translate }}</div>
        <div class="projects">
            <div class="projects-list" *ngIf="coworker.projects[0] !== null">
                <div *ngFor="let project of coworker.projects" class="project">
                    {{ project.name }}
                </div>
            </div>
            <div *ngIf="coworker.projects[0] === null">
                {{ 'coworkers.no-projects-assigned' | translate }}
            </div>
        </div>
    </div>
</div>
