<div class="content">
    <div class="coworkers row" *ngIf="!isSearching">
        <div class="button-container" [style.justify-content]="searchActive ? 'end' : 'flex-end'">
            <mat-tab-group *ngIf="!searchActive" (selectedTabChange)="onTabSelectionChange($event)" class="tabs">
              <mat-tab label="Engineers"></mat-tab>
              <mat-tab label="QA"></mat-tab>
              <mat-tab label="PM"></mat-tab>
              <mat-tab label="HR"></mat-tab>
              <mat-tab label="Admin"></mat-tab>
            </mat-tab-group>
            <app-filter
              (onChange)="onActiveChange($event)"
              [defaultSelected]="activeButtons[0]"
              [elements]="activeButtons"
              [isCircle]="true"
            ></app-filter>
          </div>
        <div [ngClass]="!receivedFlag ? 'closed-nav-coworker coworker' : 'col-xl-3 col-lg-6 col-12 coworker'" *ngFor="let coworker of coworkers">
            <app-coworker-card [coworker]="coworker" (onCoworkerEdit)="editCoworker(coworker)"></app-coworker-card>
        </div>
    </div>
    <div class="coworkers-loading" *ngIf="isSearching">
      <div class="logo-loading"></div>
    </div>
</div>
