import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { environment } from '../environments/environment';

export function getAuthServiceProviders() {
    return [
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleKey, { oneTapEnabled: false }),
        },
    ];
}
