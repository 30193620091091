import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrokedButtonComponent } from './stroked-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, MatButtonModule],
    declarations: [StrokedButtonComponent],
    exports: [StrokedButtonComponent],
})
export class StrokedButtonModule {}
