import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COWORKER_TYPE, DEFAULT_PROFILE_PICTURE_PATH, DEFAULT_PROJECT_PICTURE_PATH, PROJECT_TYPE, RIGHT_ARROW_PATH } from '@app/constants/constants';
import { FteValue } from '@app/enum/fte-value.enum';
import { Role } from '@app/enum/role.enum';
import { DropdownItem } from '@app/model/dropdown-item.model';
import { Project } from '@app/model/project.model';
import { get } from 'lodash-es';
import { type } from 'os';

import { Coworker } from 'src/app/model/coworker.model';
import { FormBuilderService } from 'src/app/service/form-builder.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
    selector: 'app-add-coworker-to-project-dialog',
    templateUrl: './add-coworker-to-project-dialog.component.html',
    styleUrls: ['./add-coworker-to-project-dialog.component.scss'],
})
export class AddCoworkerToProjectDialogComponent {
    fteForm: UntypedFormGroup;
    fteValues: DropdownItem[];
    isReviewer: boolean = false;
    movedCoworker: Coworker;
    project: Project;
    initiatedAsReviewer: boolean = false;
    readonly defaultProfilePicPath = DEFAULT_PROFILE_PICTURE_PATH;
    readonly defaultProjectPicPath = DEFAULT_PROJECT_PICTURE_PATH;
    readonly rightArrowPath = RIGHT_ARROW_PATH;
    readonly projectType = PROJECT_TYPE;
    readonly coworkerType = COWORKER_TYPE;

    constructor(
        public matDialogRef: MatDialogRef<AddCoworkerToProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackbarService: SnackbarService,
        private formBuilderService: FormBuilderService,
    ) {
        this.movedCoworker = this.data.movedCoworker;
        this.project = this.data.project;
        this.fteValues =
            this.movedCoworker.fte === 0.5
                ? [new DropdownItem(0.5, '0.5')]
                : [new DropdownItem(0.5, '0.5'), new DropdownItem(1, '1')];
        this.isReviewer = this.movedCoworker.fte === FteValue.REVIEWER_OR_QA;
        this.initiatedAsReviewer = this.isReviewer;
        this.fteForm = this.formBuilderService.createFteForm(this.movedCoworker.fte, this.isReviewer);
    }

    save() {
        this.fteForm.markAllAsTouched();
        if (this.fteForm.valid) {
            this.movedCoworker.fte = this.fteForm.get('fte').value;
            this.matDialogRef.close(this.movedCoworker);
        } else if (this.fteForm.getRawValue().fte > 0) {
            this.snackbarService.openSnackbarWithMessage('project.coworker-fte-exceeded');
        } else {
            this.snackbarService.openSnackbarWithMessage('project.required-values');
        }
    }

    isEngineer() {
        return this.movedCoworker.role === Role.ENGINEER;
    }

    disableFteFieldByCheckboxValue(isReviewer: boolean) {
        this.isReviewer = isReviewer;
        this.isReviewerChecked.setValue(isReviewer);

        if (isReviewer) {
            this.fte.setValue(FteValue.REVIEWER_OR_QA);
            this.fte.disable();
        } else {
            this.fte.setValue(this.movedCoworker.fte);
            this.fte.enable();
        }
    }

    getBackgroundImage(type: string) {
        const imagePath = type === this.projectType ? this.project.projectPicturePath : this.movedCoworker.profilePicturePath;
        const defaultPath = type === this.projectType ? this.defaultProjectPicPath : this.defaultProfilePicPath;
      
        return imagePath ? `url(${imagePath})` : `url(${defaultPath})`;
    }

    get fte() {
        return this.fteForm.get('fte');
    }

    get isReviewerChecked() {
        return this.fteForm.get('isReviewer');
    }
}
