import { EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import WorklogData from '@model/worklog-data';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { WorkingTimes } from '../model/working-times.model';
import { WorkingTimesByProjects } from '../model/working-times-by-projects.model';
import { Worklog } from '../model/worklog.model';
import { WorklogTimesheet } from '../model/worklog-timesheet.model';
import { WorklogRequest } from '../model/worklog-request.model';
import { WorklogUpdateRequest } from '@app/model/worklog-update-request.model';

// TODO refactor method paths to follow rest naming conversion
@Injectable({
    providedIn: 'root',
})
export class WorklogService {
    readonly worklogsPath = '/worklogs';

    worklogs: Worklog[];
    worklogChange = new EventEmitter<void>();

    constructor(
        private http: HttpClient,
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorage: TokenStorageService,
        private router: Router,
    ) {}

    getTimesheets() {
        return this.httpService.get(this.worklogsPath + '/worklogs');
    }

    getWorklogByMonthAndUser(dateFilter: string): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}/timesheet/?yearMonth=${dateFilter}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogByToday(): Observable<Worklog[]> {
        return this.httpService.get(`${this.worklogsPath}?isToday=true&coworkerId=${this.tokenStorage.getUserId()}`);
    }

    getWorklogByYesterday(): Observable<Worklog[]> {
        return this.httpService.get(`${this.worklogsPath}?isToday=false&coworkerId=${this.tokenStorage.getUserId()}`);
    }

    getWorklogByDay(isToday: boolean): Observable<Worklog[]> {
        return this.httpService.get(
            `${this.worklogsPath}?isToday=${isToday}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    saveWorklog(worklog: WorklogRequest) {
        return this.httpService.post(this.worklogsPath, worklog);
    }

    updateWorklog(worklogId: number, worklog: WorklogUpdateRequest) {
        return this.httpService.patch(this.worklogsPath + '/' + worklogId, worklog);
    }

    deleteWorklog(worklogId: number) {
        return this.httpService.delete(`/worklogs/${worklogId}`);
    }

    getWorkingTimes(): Observable<WorkingTimes> {
        return this.httpService.get(`${this.worklogsPath}/workingtimes/` + this.tokenStorage.getUserId());
    }

    getWorkingTimesByProjects(isWeekly: boolean): Observable<WorkingTimesByProjects[]> {
        return this.httpService.get(
            `${this.worklogsPath}/projecttimes/?isWeekly=${isWeekly}&coworkerId=${this.tokenStorage.getUserId()}`,
        );
    }

    getWorklogsByCoworker(id: number): Observable<WorklogTimesheet[]> {
        return this.httpService.get(`${this.worklogsPath}/worklogs/` + id);
    }

    downloadExportedWorklogs(userId: number, projectId: number): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(
            `${environment.serverUrl}${this.worklogsPath}/export?userId=${userId}&projectId=${projectId}`,
            {
                observe: 'response',
                responseType: Blob.name as 'json',
            },
        );
    }

    getAllWorklogData(coworkerId: number, projectId: number, from: string, to: string): Observable<WorklogData[]> {
        const params = new HttpParams()
            .set('coworkerId', String(coworkerId))
            .set('projectId', String(projectId))
            .set('from', from)
            .set('to', to);
        return this.http.get<WorklogData[]>(`${environment.serverUrl}${this.worklogsPath}/data`, { params });
    }

    getWorklogByDate(date: string): Observable<Worklog[]> {
        const params = new HttpParams().set('coworkerId', this.tokenStorage.getUserId()).set('pickedDate', date);
        return this.http.get<Worklog[]>(`${environment.serverUrl}${this.worklogsPath}/date`, { params });
    }
}
