import { Component, ViewEncapsulation, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CalendarColor } from '@app/enum/calendar-color.enum';

@Component({
    selector: 'app-color-selector',
    templateUrl: 'color-selector.component.html',
    styleUrls: ['color-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorSelectorComponent),
            multi: true,
        },
    ],
})
export class ColorSelectorComponent implements ControlValueAccessor {
    @Input() formControlName: string = 'calendarColor';
    @Input() colorList: any = [
        CalendarColor.SUN_YELLOW,
        CalendarColor.ORANGE,
        CalendarColor.BRIGHT_RED,
        CalendarColor.WINE_RED,
        CalendarColor.PINK,
        CalendarColor.GREY,
        CalendarColor.GREYISH_GREEN,
        CalendarColor.GREEN,
        CalendarColor.FOREST_GREEN,
        CalendarColor.DARK_GREEN,
        CalendarColor.STEEL_GREY,
        CalendarColor.DARK_BLUE,
        CalendarColor.INTERN_BLUE,
        CalendarColor.TURQUOISE,
        CalendarColor.LAKE_GREEN,
    ];
    @Output() valueChange = new EventEmitter<any>();
    colors = new FormControl();
    selectedOption: string = CalendarColor.SUN_YELLOW;

    writeValue(value: any) {
        this.selectedOption = value;
        this.valueChange.emit(this.selectedOption);
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touch fn
    }

    onColorChange({ value }: MatSelectChange) {
        this.selectedOption = value;
        if (this.propagateChange) {
            this.propagateChange(this.selectedOption);
        }
        this.valueChange.emit(this.selectedOption);
    }
}
