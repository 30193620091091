import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProjectAccordion } from '../../../../model/project-accordion.model';
import { WorklogAccordion } from '../../../../model/worklog-accordion.model';
import { WorklogTimesheet } from '../../../../model/worklog-timesheet.model';

@Component({
    selector: 'app-timesheet-dialog-worklog',
    templateUrl: './timesheet-dialog-worklog.component.html',
    styleUrls: ['./timesheet-dialog-worklog.component.scss'],
})
export class TimesheetDialogWorklogComponent implements OnChanges {
    @Input() worklogs: WorklogTimesheet[];

    projects;

    ngOnChanges(changes: SimpleChanges) {
        this.worklogsInit();
    }

    worklogsInit() {
        this.projects = {};
        this.worklogs.forEach((w) => {
            if (!(w.projectName in this.projects)) {
                const pa: ProjectAccordion = new ProjectAccordion();
                pa.worklogs = [];
                pa.isOpen = false;
                this.projects[w.projectName] = pa;
            }
            const worklogAccordion: WorklogAccordion = new WorklogAccordion();
            worklogAccordion.time = w.time;
            worklogAccordion.task = w.task;
            worklogAccordion.description = w.description;
            this.projects[w.projectName].worklogs.push(worklogAccordion);
        });
    }
}
