<div class="folder" (click)="openFolder()">
    <div class="buttons d-flex flex-row-reverse" *ngIf="tokenService.isAdmin()">
        <div class="button" (click)="deleteFolder(); $event.stopPropagation()">
            <mat-icon>delete</mat-icon>
        </div>
        <div class="button" (click)="editFolder(); $event.stopPropagation()">
            <mat-icon>edit</mat-icon>
        </div>
    </div>
    <mat-icon class="folder-icon">folder</mat-icon>
    <div class="folder-name">{{ folder.name }}</div>
</div>
