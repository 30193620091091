<div class="dialog fte-dialog">
    <form autocomplete="off" [formGroup]="fteForm">
        <div class="dialog-header">
            <div class="header-block">
                <div
                class="profile-icon mt-1"
                [style.background-image]="getBackgroundImage('coworker')"
                >
                </div>
                <div class="name" title="{{ movedCoworker.name }}">{{ movedCoworker.name }}</div>
            </div>
            <div
            class="arrow-icon mt-1"
            [style.background-image]="'url(' + rightArrowPath + ')'"
            >
            </div>
            <div class="header-block">
                <div
                class="profile-icon mt-1"
                [style.background-image]="getBackgroundImage('project')"
                >
                </div>
                <div class="name" title="{{ project.name }}">{{ project.name }}</div>
            </div>
        </div>
        <p class="inputTitle">{{ 'coworkers.addition.fte' | translate }}*</p>
        <app-select [options]="fteValues" formControlName="fte" [disabled]="isReviewer"></app-select>
        <div class="checkbox-container" *ngIf="isEngineer()">
            <app-checkbox
              [defaultChecked]="false"
              [label]="'coworkers.addition.reviewer' | translate"
              (onCheckboxChange)="disableFteFieldByCheckboxValue($event)"
              [value]="isReviewer"
              [disabled]="initiatedAsReviewer"
            ></app-checkbox>
          </div>
    </form>
    <div class="d-flex justify-content-between buttons">
        <div class="button-container">
            <app-stroked-button class="btn" [title]="'globalconstant.cancel' | translate" [mat-dialog-close]="false">
            </app-stroked-button>
        </div>
        <div class="button-container">
            <app-blue-button class="btn" [title]="'globalconstant.save' | translate" (onButtonClick)="save()">
            </app-blue-button>
        </div>
    </div>
</div>
