<div class="page-title-container">
    <h1 class="title">{{ 'sidebar.co-workers' | translate }}</h1>
</div>
<div class="main-container">
    <div
        class="d-flex align-items-center justify-content-between coworkersMenu"
        *ngIf="tokenStorageService.isAdmin() || tokenStorageService.isPM()"
    >
        <div class="searchBarContainer">
            <app-searchbar
                [onSearch]="filterCoworkersBySearchTerm.bind(this)"
                [onInputChange]="searchCoworkerByName.bind(this)"
            ></app-searchbar>
        </div>
        <div class="verticalSpacer"></div>
        <div class="new-coworker" (click)="openCoworkerAdditionDialog()">
            <mat-icon>add</mat-icon>
            <div class="new-coworker-text">{{ 'coworkers.addCoworker' | translate }}</div>
        </div>
    </div>
    <app-coworker-list
        (selectedActivatedItem)="setActivatedFilter($event)"
        (selectedCoworker)="onSelectedChange($event)"
        (tabSelectionChange)="onTabSelectionChange($event)"
        [searchActive]="searchActive"
        [coworkers]="displayedCoworkers"
        [isSearching]="isSearching"
    ></app-coworker-list>
</div>
