import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TabFilter } from '@model/filter/tab-filter.interface';

@Component({
    selector: 'app-filter',
    templateUrl: 'filter.component.html',
    styleUrls: ['filter.component.scss'],
})
export class FilterComponent implements OnInit {
    @Input() elements: TabFilter[];
    @Input() defaultSelected?;
    @Input() allowInitTrigger?: boolean = false;
    @Input() isCircle?: boolean = false;
    @Output() onChange: EventEmitter<TabFilter> = new EventEmitter();
    selectedValue?: TabFilter;

    ngOnInit() {
        this.selectedValue = this.defaultSelected ? this.defaultSelected : this.elements[0];
        if (this.allowInitTrigger) {
            this.onSelect(this.selectedValue);
        }
    }

    onSelect(value: TabFilter) {
        this.selectedValue = value;
        this.onChange.emit(value);
    }
}
