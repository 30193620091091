import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./view/login/login-routing.module').then((m) => m.LoginRoutingModule),
    },
    {
        path: '',
        loadChildren: () =>
            import('./view/authenticated-root/authenticated-root-routing.module').then(
                (m) => m.AuthenticatedRootRoutingModule,
            ),
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
