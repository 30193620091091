<div class="dialog worklog-dialog">
    <div class="row">
        <div class="col-3">
            <div class="nav">
                <ul>
                    <li (click)="navChange(0)" [class.active]="activeButton === 0">
                        {{ 'timesheet.details' | translate }}
                    </li>
                    <li (click)="navChange(1)" [class.active]="activeButton === 1">
                        {{ 'timesheet.worklog-label' | translate }}
                    </li>
                    <li (click)="navChange(2)" [class.active]="activeButton === 2">
                        {{ 'timesheet.timesheet-label' | translate }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-9 content">
            <div class="scroll">
                <div class="dialog-header">
                    <h1 class="name">
                        {{ project.coworker.name }}
                    </h1>
                    <div *ngIf="activeButton === 1" class="export-button">
                        <button
                            (click)="downloadExportedWorklogs()"
                            mat-mini-fab
                            color="primary"
                            aria-label="Excel Export"
                        >
                            <mat-icon svgIcon="download-icon"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="buttons">
                    <app-filter
                        (onChange)="doFilter($event)"
                        [defaultSelected]="intervalButtons[0]"
                        [elements]="intervalButtons"
                    ></app-filter>
                </div>
                <div class="worklog">
                    <app-timesheet-dialog-details
                        [projectName]="project.name"
                        [worklogs]="detailsFilter()"
                        *ngIf="activeButton === 0"
                    ></app-timesheet-dialog-details>
                    <app-timesheet-dialog-worklog [worklogs]="filteredWorklogs" *ngIf="activeButton === 1">
                    </app-timesheet-dialog-worklog>
                    <app-timesheet-dialog-timesheet [worklogs]="filteredWorklogs" *ngIf="activeButton === 2">
                    </app-timesheet-dialog-timesheet>
                </div>
            </div>
            <div class="time-sum">{{ 'timesheet.altogether' | translate }}: {{ sumHours() }}</div>
        </div>
    </div>
</div>
