<div class="timesheet basic-other-container">
    <mat-accordion class="accordion">
        <!--TODO: Refactor - this is probably not working -->
        <mat-expansion-panel
            class="panel"
            *ngFor="let item of projects | keyvalue"
            (opened)="item.value['isOpen'] = true"
            (closed)="item.value['isOpen'] = false"
        >
            <mat-expansion-panel-header class="header">
                <mat-panel-title class="title">
                    <div [class.active-title]="item.value['isOpen']">{{ item.key }}</div>
                    <mat-icon [class.active]="item.value['isOpen']" class="icon">{{
                        item.value['isOpen'] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                    }}</mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="worklog" *ngFor="let worklog of item.value['worklogs']">
                <div class="worklog-header">
                    <div class="task-title">
                        {{ worklog.task }}
                    </div>
                    <div class="task-time">
                        {{ worklog.time }}
                    </div>
                </div>
                <div class="worklog-desc">{{ worklog.description }}</div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
