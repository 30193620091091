import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FilterComponent } from './filter.component';

@NgModule({
    imports: [CommonModule, TranslateModule, MatIconModule],
    declarations: [FilterComponent],
    exports: [FilterComponent],
    providers: [MatNativeDateModule],
})
export class FilterModule {}
