<div class="details-content">
    <h2 class="project-title">{{ projectName }}</h2>

    <div class="worklog" *ngFor="let worklog of worklogs">
        <div class="header">
            <div class="worklog-title">{{ worklog.task }}</div>
            <div class="hours">{{ worklog.time }}</div>
        </div>
        <div class="description">
            {{ worklog.description }}
        </div>
    </div>
</div>
