export enum FileType {
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png',
}

export function getImageTypes(): string[] {
    return [FileType.JPG, FileType.JPEG, FileType.PNG];
}

export function getAcceptImageTypes(): string {
    return getImageTypes()
        .map((type) => '.' + type)
        .join(', ');
}
