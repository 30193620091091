<!-- Scroll down for the currently active parts of the form! -->
<div class="coworker-addition">
    <div class="form-container-wrapper">
        <form autocomplete="off" [formGroup]="coworkerForm">
            <div class="row-one">
                <!-- Page title & active/inactive checkbox -->

                <div class="d-flex justify-content-between align-items-baseline">
                    <div class="page-title">
                        {{ title }}
                    </div>
                    <div class="checkbox-div">
                        <mat-checkbox formControlName="active" [disableRipple]="true" [checked]="true" class="checkbox">
                            <mat-icon class="checkmark" *ngIf="coworkerForm.get('active').value === true">done</mat-icon>
                            {{ 'coworkers.addition.active' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <!-- Select page (remove ngIf to activate) -->

                <div class="options" *ngIf="false">
                    <div class="form-option" *ngFor="let item of navItems" [class.active]="activeItem.id === item.id">
                        <span (click)="onItemChange(item)"> {{ item.name | translate }} </span>
                    </div>
                </div>

                <!-- Main body of the form -->
            </div>
            <div class="form-row row-two">
                <form autocomplete="off" [formGroup]="coworkerForm" *ngIf="false">
                    <div class="input-fields" *ngIf="activeItem.id === 'PROFILE'; else fileUpload">
                        <div class="form-left">
                            <div class="flex-container user-data">
                                <div class="flex-container">
                                    <div
                                        class="profile-picture mt-1"
                                        [style.background-image]="'url(' + coworker.profilePicturePath + ')'"
                                    ></div>
                                    <div class="name">
                                        <div class="coworker-name">
                                            {{ title }}
                                        </div>
                                        <div class="image-upload">
                                            <input
                                                type="file"
                                                class="file-input"
                                                style="display: none"
                                                (change)="profilePictureUpload($event)"
                                                #pictureUpload
                                            />
                                            <span class="file-input" (click)="pictureUpload.click()">{{
                                                'coworkers.addition.profile-img' | translate
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="primary-title flex-container">
                                <div>
                                    {{ 'coworkers.addition.primary-data' | translate }}
                                </div>
                                <div class="checkbox-div">
                                    <mat-checkbox formControlName="active" [disableRipple]="true" class="checkbox">                                        
                                        {{ 'coworkers.addition.active' | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>

                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.name' | translate"
                                formControlName="name"
                            >
                            </app-input>
                            <app-select
                                [required]="true"
                                [options]="jobTypes"
                                [label]="'coworkers.addition.job-title' | translate"
                                formControlName="jobType"
                            ></app-select>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.tax-number' | translate"
                                formControlName="taxNumber"
                            >
                            </app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.account-number' | translate"
                                formControlName="creditCardNumber"
                            >
                            </app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.e-mail' | translate"
                                formControlName="email"
                            >
                            </app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.phone-number' | translate"
                                formControlName="phoneNumber"
                            >
                            </app-input>

                            <app-input
                                type="number"
                                [max]="9"
                                [min]="1"
                                [required]="true"
                                [label]="'coworkers.addition.e-number' | translate"
                                formControlName="enumber"
                            >
                            </app-input>

                            <app-input
                                type="number"
                                [max]="1"
                                [min]="0"
                                [required]="true"
                                [label]="'coworkers.addition.fte' | translate"
                                formControlName="fte"
                            >
                            </app-input>
                        </div>
                        <div class="form-right">
                            <div class="secondary-title">
                                {{ 'coworkers.address' | translate }}
                            </div>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.country' | translate"
                                formControlName="country"
                            >
                            </app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.zip-code' | translate"
                                formControlName="zipCode"
                            ></app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.city' | translate"
                                formControlName="city"
                            >
                            </app-input>
                            <app-input
                                [required]="true"
                                [label]="'coworkers.addition.street' | translate"
                                formControlName="street"
                            >
                            </app-input>

                            <div class="secondary-title">{{ 'coworkers.addition.holidays' | translate }} *</div>

                            <app-input [required]="true" formControlName="holidays"></app-input>
                        </div>
                    </div>

                    <ng-template #fileUpload>
                        <div class="fileInput">
                            <div class="primary-title">
                                {{ activeItem.name }}
                            </div>

                            <div
                                class="file d-flex flex-row justify-content-between"
                                *ngFor="let document of getOldFiles()"
                            >
                                <div (click)="downloadOldFile(document)" class="file-name">
                                    {{ document.originalName }}
                                </div>
                                <div class="remove" (click)="removeOldDocument(document)">X</div>
                            </div>

                            <div
                                class="file d-flex flex-row justify-content-between"
                                *ngFor="let file of getNewFiles()"
                            >
                                <div (click)="downloadNewFile(file)" class="file-name">
                                    {{ file.name }}
                                </div>
                                <div class="remove" (click)="removeDocument(file)">X</div>
                            </div>

                            <div class="new-document-div">
                                <input
                                    type="file"
                                    class="file-input"
                                    style="display: none"
                                    (change)="documentUpload($event)"
                                    #fileUpload
                                />
                                <span class="new-document" (click)="fileUpload.click()">
                                    {{ 'coworkers.addition.new-document' | translate }}
                                </span>
                            </div>
                        </div>
                    </ng-template>

                    <div class="button-container">
                        <app-stroked-blue-button
                            *ngIf="selectedCoworker"
                            [title]="'globalconstant.cancel' | translate"
                            (onButtonClick)="clearForm()"
                        ></app-stroked-blue-button>

                        <app-blue-button (onButtonClick)="onSubmit()" [title]="'globalconstant.save' | translate">
                        </app-blue-button>
                    </div>

                    <div class="error-container" *ngIf="formErrors.length > 0">
                        <mat-error *ngFor="let error of formErrors">{{ error | translate }}</mat-error>
                    </div>
                </form>
                <!-- ↓ THIS IS THE ACTIVE PART OF THE FORM, CURRENTLY AVAILABLE IN PHASE2 ↓  -->

                <!-- Primary section -->
                <!--  Some parts are disabled with *ngIf="false", they are in their final place and ready, but are not yet needed.
              They should be enabled by simply removing the ngIF condition.
              Address section is also ready!
        -->

                <div class="section-title">
                    {{ 'coworkers.addition.primary-data' | translate }}
                </div>

                <div class="separator"></div>

                <div class="user-data-container">

                    <div class="flex-container user-data">
                        <div class="flex-container align-items-center">
                            <div
                                class="profile-picture"
                                [style.background-image]="
                                    'url(' + coworker.profilePicturePath + ') , url(' + defaultProfilePicPath + ')'
                                "
                            ></div>
                            <div class="name">
                                <div class="image-upload">
                                    <input
                                        type="file"
                                        class="file-input"
                                        style="display: none"
                                        (change)="profilePictureUpload($event)"
                                        #pictureUpload
                                    />
                                    <img
                                        class="upload-icon"
                                        src="assets/icons/pencil.svg"
                                        (click)="pictureUpload.click()"
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
    
                    <mat-grid-list cols="3" rowHeight="90px" class="inputGrid">
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.name' | translate }}*</p>
                                <app-input formControlName="name"> </app-input>
                            </div>
                        </mat-grid-tile>
    
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.role' | translate }}*</p>
                                <app-select [options]="roles" formControlName="role"></app-select>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile class="intern-container">
                            <mat-checkbox class="intern-checkmark-container" *ngIf="isRoleInternshipAvailable()" formControlName="intern" [disableRipple]="true">
                                <mat-icon class="checkmark" *ngIf="coworkerForm.get('intern').value === true">done</mat-icon>
                                {{ 'coworkers.addition.intern' | translate }}
                            </mat-checkbox>
                        </mat-grid-tile>
                       
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.e-mail' | translate }}*</p>
                                <app-input formControlName="email"> </app-input>
                            </div>
                        </mat-grid-tile>
    
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">
                                    {{ 'coworkers.addition.fte' | translate }}<span *ngIf="isRoleWithFteSelected()">*</span>
                                </p>
                                <app-select
                                    [options]="fteValues"
                                    [fontColor]="'dark'"
                                    [required]="isRoleWithFteSelected()"
                                    [disabled]="!isRoleWithFteSelected()"
                                    formControlName="fte"
                                >
                                </app-select>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile class="intern-color-container" *ngIf="coworkerForm.get('intern').value === true">
                            <app-color-selector [colorList]="colorList" formControlName="calendarColor"></app-color-selector>
                            <mat-label class="calendar-color-label">{{ 'coworkers.addition.calendar-color' | translate }}</mat-label>
                        </mat-grid-tile>
    
                        <mat-grid-tile *ngIf="false">
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.job-title' | translate }}*</p>
                                <app-select [options]="jobTypes" formControlName="jobType"></app-select>
                            </div>
                        </mat-grid-tile>
    
                        <mat-grid-tile *ngIf="false">
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.tax-number' | translate }}</p>
                                <app-input formControlName="taxNumber"> </app-input>
                            </div>
                        </mat-grid-tile>
    
    
                        <mat-grid-tile *ngIf="false">
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.phone-number' | translate }}*</p>
                                <app-input formControlName="phoneNumber"></app-input>
                            </div>
                        </mat-grid-tile>
    
                        <mat-grid-tile *ngIf="false">
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.e-number' | translate }}*</p>
                                <app-input type="number" [max]="9" [min]="1" formControlName="enumber"> </app-input>
                            </div>
                        </mat-grid-tile>
    
                        <mat-grid-tile *ngIf="false">
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.account-number' | translate }}*</p>
                                <app-input formControlName="creditCardNumber"></app-input>
                            </div>
                        </mat-grid-tile>
                        
                    </mat-grid-list>
                </div>

                <div  *ngIf="!coworkerForm.get('intern').value">
                    <div class="section-title">
                        {{ 'coworkers.addition.holidays' | translate }}
                    </div>

                    <div class="separator"></div>
                    <mat-grid-list cols="4" rowHeight="90px" class="inputGrid">
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">
                                    {{ 'dashboard.holidays.total' | translate }}<span>*</span>
                                </p>
                                <app-input type="number" formControlName="totalHolidays"></app-input>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">
                                    {{ 'dashboard.holidays.remaining' | translate }}<span>*</span>
                                </p>
                                <app-input type="number" formControlName="remainingHolidays"></app-input>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>

                <!-- Address section -->
                <!-- When enabling address section, you can set the .coworker-addition top property to 40px to fix the alignment! -->
                <div *ngIf="false">
                    <div class="section-title marginTop">
                        {{ 'coworkers.address' | translate }}
                    </div>

                    <div class="separator"></div>

                    <mat-grid-list cols="4" rowHeight="90px" class="inputGrid">
                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.country' | translate }}*</p>
                                <app-input [required]="true" formControlName="country"> </app-input>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.city' | translate }}*</p>
                                <app-input [required]="true" formControlName="city"> </app-input>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.holidays' | translate }}*</p>
                                <app-input [required]="true" formControlName="holidays"></app-input>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile>
                            <div class="inputContainer"></div>
                        </mat-grid-tile>

                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.street' | translate }}*</p>
                                <app-input [required]="true" formControlName="street"> </app-input>
                            </div>
                        </mat-grid-tile>

                        <mat-grid-tile>
                            <div class="inputContainer">
                                <p class="inputTitle">{{ 'coworkers.addition.zip-code' | translate }}*</p>
                                <app-input [required]="true" formControlName="zipCode"></app-input>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>

                <!-- ↑ THIS IS THE ACTIVE PART OF THE FORM, CURRENTLY AVAILABLE IN PHASE2 ↑  -->

                <ng-template #fileUpload>
                    <div class="fileInput">
                        <div class="primary-title">
                            {{ activeItem.name }}
                        </div>

                        <div
                            class="file d-flex flex-row justify-content-between"
                            *ngFor="let document of getOldFiles()"
                        >
                            <div (click)="downloadOldFile(document)" class="file-name">
                                {{ document.originalName }}
                            </div>
                            <div class="remove" (click)="removeOldDocument(document)">X</div>
                        </div>

                        <div class="file d-flex flex-row justify-content-between" *ngFor="let file of getNewFiles()">
                            <div (click)="downloadNewFile(file)" class="file-name">
                                {{ file.name }}
                            </div>
                            <div class="remove" (click)="removeDocument(file)">X</div>
                        </div>

                        <div class="new-document-div">
                            <input
                                type="file"
                                class="file-input"
                                style="display: none"
                                (change)="documentUpload($event)"
                                #fileUpload
                            />
                            <span class="new-document" (click)="fileUpload.click()">
                                {{ 'coworkers.addition.new-document' | translate }}
                            </span>
                        </div>
                    </div>
                </ng-template>

                <div class="button-container">
                    <app-red-button
                        *ngIf="coworker.id"
                        [disabled]="saving"
                        (buttonClick)="onDelete()"
                        [title]="'globalconstant.delete' | translate"
                    >
                    </app-red-button>

                    <app-stroked-blue-button
                        [disabled]="saving"
                        (onButtonClick)="onCancel()"
                        [title]="'globalconstant.cancel' | translate"
                    >
                    </app-stroked-blue-button>

                    <app-blue-button
                        [disabled]="saving"
                        (onButtonClick)="onSubmit()"
                        [title]="'globalconstant.save' | translate"
                    >
                    </app-blue-button>
                </div>

                <div class="error-container" *ngIf="formErrors.length > 0">
                    <mat-error *ngFor="let error of formErrors">{{ error | translate }}</mat-error>
                </div>
            </div>
        </form>
    </div>
</div>
