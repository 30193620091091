import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { phone } from 'phone';

export function hasLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return (control.value ?? '').trim().length === 0 ? { hasLength: false } : null;
    };
}

export function PhoneNumberValidator(regionCode?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const phoneNumber = phone(control.value, { country: regionCode, validateMobilePrefix: true });

        return phoneNumber.isValid ? null : { validPhoneNumber: false };
    };
}

export function EmailFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value?.match('[\\w-\\.\\+]+@([\\w-]+\\.)+[\\w-]{2,4}$') ? null : { emailFormat: false };
    };
}

export function NotDefaultValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value === 'default' ? { notDefaultValue: true } : null;
    };
}

export function SmallerThanOrEqual(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        const thisValue = control.value;
        const otherValue = control.parent.get(otherControlName).value;

        if (thisValue <= otherValue) {
            return null;
        }

        return {
            smallerThan: true,
        };
    };
}

export function RequiredIf(otherField: string, requiredValue: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const otherValue = control.get(otherField)?.value;

        return otherValue === requiredValue && !control.value ? { RequiredIf: true } : null;
    };
}
