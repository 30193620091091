import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    save(file: Blob, filename: string): void {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = filename;

        link.click();
        link.remove();
    }
}
