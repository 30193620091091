import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoleTranslationKeys } from 'src/app/constants/constants';
import { Coworker } from 'src/app/model/coworker.model';
import { DEFAULT_PROFILE_PICTURE_PATH } from 'src/app/constants/constants';

@Component({
    selector: 'app-coworker-card',
    templateUrl: './coworker-card.component.html',
    styleUrls: ['./coworker-card.component.scss'],
})
export class CoworkerCardComponent {
    @Input() coworker: Coworker = null;
    @Output() onCoworkerEdit = new EventEmitter<any>();
    readonly COWORKER_MAX_LENGTH: number = 18;
    readonly RoleTranslationKeys = RoleTranslationKeys;
    readonly defaultProfilePicPath = DEFAULT_PROFILE_PICTURE_PATH;

    onClick = () => this.onCoworkerEdit.emit();
}
