import { AlertType } from '../enum/alert-type.enum';

export class Alert {
    type: AlertType;
    title: string;
    message: string;

    constructor(data?) {
        if (data) {
            this.type = data.type;
            this.title = data.title;
            this.message = data.message;
        }
    }
}
