<div
    class="w-100"
>
    <mat-form-field [class.disabled]="isDisabled">
        <mat-label class="ps-2">{{ label | translate }}</mat-label>
        <input
            matInput
            formControlName="formControlName"
            required="{{ required }}"
            autocomplete="off"
            [placeholder]="placeholder"
            [value]="inputValue"
            [max]="max"
            [min]="min"
            [step]="step"
            [disabled]="isDisabled"
            [maxLength]="maxLength"
            (input)="valueChange($event)"
        />

        <button
            class="remove-btn"
            *ngIf="inputValue && removeSuffix"
            matSuffix
            mat-icon-button
            tabIndex="-1"
            (click)="removeValue()"
        >
            <mat-icon>close</mat-icon>
        </button>

        <mat-error class="error" *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>
</div>
