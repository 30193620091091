import { Address } from './address.model';
import { Project } from './project.model';
import { Worklog } from './worklog.model';
import { Document } from './document.model';
import { Role } from '../enum/role.enum';
import { CalendarColor } from '@app/enum/calendar-color.enum';

export class Coworker {
    public id: number;
    public name: string;
    public email: string;
    public enumber: number;
    public fte: number;
    public activated: boolean;
    public jobTitle: string;
    public profilePicturePath: any;
    public projects: Project[];
    public contracts: Document[];
    public documents: Document[];
    public labourDocuments: Document[];
    public statements: Document[];
    public lastUpdate: Date;
    public worklogs: Worklog[];
    public missingDays: number;
    public taxNumber: string;
    public creditCardNumber: string;
    public phoneNumber: string;
    public address: Address;
    public role: Role;
    public pending: boolean;
    public totalHolidays: number;
    public remainingHolidays: number;
    public intern: boolean;
    public calendarColor: CalendarColor;
}
