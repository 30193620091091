import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-red-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'red-button.component.html',
    styleUrls: ['red-button.component.scss'],
})
export class RedButtonComponent {
    @Input() title: string;
    @Input() disabled: boolean = false;
    @Output() buttonClick = new EventEmitter<any>();

    onClick = () => this.buttonClick.emit();
}
