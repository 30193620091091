import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectComponent } from './multi-select.component';

@NgModule({
    imports: [CommonModule, TranslateModule, MatSelectModule, FormsModule, ReactiveFormsModule],
    declarations: [MultiSelectComponent],
    exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
