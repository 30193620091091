<div class="dialog">
    <div class="text">
        <p>{{ data.message | translate }}</p>
    </div>
    <textarea class="reason" placeholder="Reasoning" [(ngModel)]="reasoning">{{ 'holidays.holiday-decline-reasoning' | translate}}</textarea>
    <p class="inputLength">{{reasoning.length}}/{{MAX_MESSAGE_LENGTH}}</p>
    <div class="d-flex justify-content-between buttons">
         <app-stroked-blue-button
            [title]="data.no | translate"
            [mat-dialog-close]="true"
            (onButtonClick)="close(false)"
        >
        </app-stroked-blue-button>
        <app-blue-button 
            [title]="data.yes | translate" 
            [disabled] = "!canBeSent()" 
            (onButtonClick)="canBeSent() && handleButtonClick()"
            [class.disabledButton]="!canBeSent()"
         >
        </app-blue-button>
        
    </div>
</div>
