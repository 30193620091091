const HOUR_INDEX: number = 1;
const MIN_INDEX: number = 2;
const ONE_HOUR_IN_MINUTES = 60;

export const DURATION_FORMAT_REGEX = /^([0-9][0-9]):([0-5][0-9])$/;

export function convertDurationStringToMin(value: string): number {
    let matches = value.match(DURATION_FORMAT_REGEX);
    return matches && matches.length === 3
        ? Number(matches[HOUR_INDEX]) * ONE_HOUR_IN_MINUTES + Number(matches[MIN_INDEX])
        : undefined;
}
