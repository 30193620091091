<div class="dialog">
    <div class="title">{{ (document.id ? 'documents.edit-document' : 'documents.new-document') | translate }}</div>
    <form [formGroup]="documentForm">
        <div>
            <app-input [required]="true" [label]="'documents.document-name' | translate" formControlName="name">
            </app-input>
        </div>
        <div class="new-document-div">
            <input type="file" class="file-input" style="display: none" (change)="documentUpload($event)" #fileUpload />
            <div class="new-document" (click)="fileUpload.click()">
                {{ file ? file.name : ('coworkers.addition.new-document' | translate) + ' *' }}
            </div>
        </div>
        <div>
            <app-multi-select
                [selectOption]="false"
                [required]="false"
                [label]="'documents.one-time-open' | translate"
                formControlName="oneTimeOpenPermission"
                [options]="oneTimePermissions"
            ></app-multi-select>
        </div>
        <div class="d-flex justify-content-between buttons">
            <span class="button-container">
                <app-stroked-button [title]="'globalconstant.cancel' | translate" [mat-dialog-close]="true">
                </app-stroked-button>
            </span>
            <span class="button-container">
                <app-blue-button [title]="'globalconstant.save' | translate" (onButtonClick)="onSubmit()">
                </app-blue-button>
            </span>
        </div>
    </form>
</div>
