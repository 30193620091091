<div class="dialog">
    <div class="text">
        <p>{{ data.message | translate }}</p>
    </div>
    <div class="d-flex justify-content-between buttons">
        <span class="button-container">
            <app-stroked-button
                class="btn"
                [title]="data.no | translate"
                [mat-dialog-close]="true"
                (onButtonClick)="close(false)"
            >
            </app-stroked-button>
        </span>
        <span class="button-container">
            <app-blue-button class="btn" [title]="data.yes | translate" (onButtonClick)="close(true)">
            </app-blue-button>
        </span>
    </div>
</div>
