import { Component, DoCheck, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { SnackbarService } from 'src/app/service/snackbar.service';
import { Coworker } from 'src/app/model/coworker.model';
import { CoworkerService } from 'src/app/service/coworker.service';
import { Address } from 'src/app/model/address.model';
import { JobType } from 'src/app/enum/job-title.enum';
import { Document } from 'src/app/model/document.model';
import {
    DEFAULT_PROFILE_PICTURE_PATH,
    JobTitleTranslationKeys,
    RoleTranslationKeys,
} from 'src/app/constants/constants';
import { FormBuilderService } from 'src/app/service/form-builder.service';
import { Role } from 'src/app/enum/role.enum';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { FileService } from '@app/service/file-service';
import { ConfirmationDialogComponent } from '@app/view/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DropdownItem } from '@app/model/dropdown-item.model';
import { getAcceptImageTypes, getImageTypes } from '@app/enum/file-type.enum';
import { CalendarColor } from '@app/enum/calendar-color.enum';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-coworker-addition',
    templateUrl: './coworker-addition.component.html',
    styleUrls: ['./coworker-addition.component.scss'],
})
export class CoworkerAdditionComponent implements OnInit, OnChanges {
    @Input() selectedCoworker: Coworker | null = null;
    @Output() coworkerSave = new EventEmitter<Coworker>();
    coworker: Coworker = new Coworker();
    coworkerForm: UntypedFormGroup;
    jobTypes: { id: number; name: string; enumValue: string }[] = [];
    formErrors = [];
    navItems = [
        { id: 'PROFILE', name: this.translate.instant('coworkers.addition.profile') },
        { id: 'LABOUR_DOCUMENTS', name: this.translate.instant('coworkers.addition.documents') },
        { id: 'CONTRACTS', name: this.translate.instant('coworkers.addition.contracts') },
        { id: 'STATEMENTS', name: this.translate.instant('coworkers.addition.statements') },
        { id: 'OTHER_DOCUMENTS', name: this.translate.instant('coworkers.addition.other-documents') },
    ];
    activeItem: { id: string; name: string };
    documents: File[] = [];
    contracts: File[] = [];
    statements: File[] = [];
    otpCards: File[] = [];
    otherDocuments: File[] = [];
    MatDialogRef;
    removedDocuments: Document[] = [];
    rolesWithFte: string[] = [Role.ENGINEER, Role.QA, Role.HR];
    rolesWithInternship: string[] = [Role.ENGINEER, Role.QA];
    profileImage: any;
    roles: { id: number; name: string; enumValue: string }[] = [];
    fteValues: DropdownItem[] = [new DropdownItem(0.5, '0.5'), new DropdownItem(1, '1')];
    colorList: any = [
        CalendarColor.SUN_YELLOW,
        CalendarColor.ORANGE,
        CalendarColor.BRIGHT_RED,
        CalendarColor.WINE_RED,
        CalendarColor.PINK,
        CalendarColor.GREY,
        CalendarColor.GREYISH_GREEN,
        CalendarColor.GREEN,
        CalendarColor.FOREST_GREEN,
        CalendarColor.DARK_GREEN,
        CalendarColor.STEEL_GREY,
        CalendarColor.DARK_BLUE,
        CalendarColor.INTERN_BLUE,
        CalendarColor.TURQUOISE,
        CalendarColor.LAKE_GREEN,
    ];
    filteredRoles: string[] = [];
    saving: boolean = false;
    employeeId: number;
    title: string;
    holidayOptions: DropdownItem[] = [];

    readonly defaultProfilePicPath = DEFAULT_PROFILE_PICTURE_PATH;
    private readonly MAX_FILE_SIZE_BYTES = 10485760;

    constructor(
        private coworkerService: CoworkerService,
        private translate: TranslateService,
        private snackbarService: SnackbarService,
        private formBuilderService: FormBuilderService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CoworkerAdditionComponent>,
        private tokenStorageService: TokenStorageService,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: Coworker | undefined,
    ) {
        this.getJobTypes();
        this.getRoles();
        this.initHolidayDropdowns();
    }

    ngOnChanges(): void {
        if (this.selectedCoworker) {
            this.loadCoworkerWithDocuments();
        }
    }

    loadCoworkerWithDocuments() {
        this.coworkerService.getCoworkerById(this.selectedCoworker.id).subscribe((resp) => {
            this.coworker = resp;
            this.coworker.profilePicturePath = this.data.profilePicturePath;
            this.createForm();
            if (this.role) {
                this.role.setValue(this.roles.find((role) => role.enumValue === this.coworker.role)?.id);
            }
        });
    }

    ngOnInit() {
        if (this.data) {
            this.selectedCoworker = this.data;
        } else {
            this.coworker.activated = true;
        }

        this.coworker.address = new Address();
        this.createForm();
        this.activeItem = this.navItems[0];

        if (this.data) {
            this.email.disable();
            this.role.disable();
        } else {
            this.active.disable();
        }

        if (this.selectedCoworker) {
            this.loadCoworkerWithDocuments();
            this.clearForm();
            this.title = this.translate.instant('coworkers.update-coworker');

            this.coworker.profilePicturePath = this.data.profilePicturePath;
        } else {
            this.coworker.profilePicturePath = DEFAULT_PROFILE_PICTURE_PATH;
            this.title = this.translate.instant('coworkers.new-coworker');
        }
        this.filteredRoles = this.tokenStorageService.isAdmin() ? ['CLIENT'] : ['ADMIN', 'CLIENT'];
    }

    removeOldDocument(document: Document) {
        this.removedDocuments.push(document);
        let documents = this.getOldFiles();
        documents = documents.filter((i) => i.id !== document.id);
        this.setOldFiles(documents);
    }

    private createForm() {
        if (this.coworker.address === null) {
            this.coworker.address = new Address();
        }

        this.coworkerForm = this.formBuilderService.createCoworkerForm(this.coworker);
    }

    formIsValid(): boolean {
        this.formErrors = [];
        this.coworkerForm.markAllAsTouched();
        return this.coworkerForm.valid;
    }

    get enumber() {
        return this.coworkerForm.get('enumber');
    }

    get fte() {
        return this.coworkerForm.get('fte');
    }

    get active() {
        return this.coworkerForm.get('active');
    }

    get holidays() {
        return this.coworkerForm.get('holidays');
    }

    get street() {
        return this.coworkerForm.get('street');
    }

    get zipCode() {
        return this.coworkerForm.get('zipCode');
    }

    get city() {
        return this.coworkerForm.get('city');
    }

    get email() {
        return this.coworkerForm.get('email');
    }

    get phoneNumber() {
        return this.coworkerForm.get('phoneNumber');
    }

    get country() {
        return this.coworkerForm.get('country');
    }

    get jobType() {
        return this.coworkerForm.get('jobType');
    }

    get creditCardNumber() {
        return this.coworkerForm.get('creditCardNumber');
    }

    get taxNumber() {
        return this.coworkerForm.get('taxNumber');
    }

    get name() {
        return this.coworkerForm.get('name');
    }

    get hourlyRate() {
        return this.coworkerForm.get('hourlyRate');
    }

    get role() {
        return this.coworkerForm.get('role');
    }

    get totalHolidays() {
        return this.coworkerForm.get('totalHolidays');
    }

    get remainingHolidays() {
        return this.coworkerForm.get('remainingHolidays');
    }

    get intern() {
        return this.coworkerForm.get('intern');
    }

    get calendarColor() {
        return this.coworkerForm.get('calendarColor');
    }

    async getJobTypes() {
        return this.coworkerService.getJobTitles().subscribe((resp: string[]) => {
            this.jobTypes = resp.map((jobType, index) => ({
                name: this.translate.instant(JobTitleTranslationKeys[jobType]),
                id: index,
                enumValue: JobType[jobType],
            }));

            if (this.jobType && this.coworker) {
                this.jobType.setValue(this.jobTypes.find((job) => job.enumValue === this.coworker.jobTitle)?.id);
            }
        });
    }

    async getRoles() {
        return this.coworkerService.getRoles().subscribe((resp: string[]) => {
            this.roles = resp
                .map((role, index) => ({
                    name: this.translate.instant(RoleTranslationKeys[role]),
                    id: index,
                    enumValue: Role[role],
                }))
                .filter((item) => !this.filteredRoles.includes(item.enumValue));

            if (this.role && this.coworker) {
                this.role.setValue(this.roles.find((role) => role.enumValue === this.coworker.role)?.id);
            }
            this.employeeId = this.roles.find((role) => role.enumValue === Role.ENGINEER).id;
        });
    }

    clearForm() {
        this.selectedCoworker = null;
        this.coworker = new Coworker();
        this.coworker.address = new Address();
        this.removedDocuments = [];
        this.activeItem = this.navItems[0];

        this.documents = [];
        this.contracts = [];
        this.statements = [];
        this.otpCards = [];
        this.otherDocuments = [];

        this.coworkerForm.reset();
    }

    profilePictureUpload(event) {
        const file: File = event.target.files[0];

        if (file) {
            if (file.size > this.MAX_FILE_SIZE_BYTES) {
                this.snackbarService.openSnackbarWithMessage('settings.invalid-file-size');
            } else if (!getImageTypes().includes(file.name.split('.').pop())) {
                this.snackbarService.openSnackbarWithMessage('error.WRONG_FILE_EXTENSION_FOR_PROFILE_PICTURE');
            } else {
                this.coworker.profilePicturePath = URL.createObjectURL(file);
                this.profileImage = file;
            }
        }
    }

    documentUpload(event) {
        const file: File = event.target.files[0];
        if (file) {
            this.getNewFiles().push(file);
        }
    }

    getNewFiles(): File[] {
        let result: File[];
        switch (this.activeItem.id) {
        case 'LABOUR_DOCUMENTS':
            result = this.documents;
            break;
        case 'CONTRACTS':
            result = this.contracts;
            break;
        case 'STATEMENTS':
            result = this.statements;
            break;
        case 'OTHER_DOCUMENTS':
            result = this.otherDocuments;
            break;
        }
        return result;
    }

    getOldFiles(): Document[] {
        let result: Document[];
        switch (this.activeItem.id) {
        case 'LABOUR_DOCUMENTS':
            result = this.coworker.labourDocuments;
            break;
        case 'CONTRACTS':
            result = this.coworker.contracts;
            break;
        case 'STATEMENTS':
            result = this.coworker.statements;
            break;
        case 'OTHER_DOCUMENTS':
            result = this.coworker.documents;
            break;
        }
        return result;
    }

    setOldFiles(list: Document[]) {
        switch (this.activeItem.id) {
        case 'LABOUR_DOCUMENTS':
            this.coworker.labourDocuments = list;
            break;
        case 'CONTRACTS':
            this.coworker.contracts = list;
            break;
        case 'STATEMENTS':
            this.coworker.statements = list;
            break;
        case 'OTHER_DOCUMENTS':
            this.coworker.documents = list;
            break;
        }
    }

    onItemChange(item: any) {
        this.activeItem = item;
    }

    removeDocument(document: File) {
        const index = this.getNewFiles().indexOf(document);
        if (index > -1) {
            this.getNewFiles().splice(index, 1);
        }
    }

    onDelete() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    message: 'coworkers.coworker-confirm-delete',
                    yes: 'globalconstant.yes',
                    no: 'globalconstant.cancel',
                },
                panelClass: 'confirm-dialog',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.value) {
                    this.coworkerService.deleteCoworker(this.coworker.id).subscribe(() => {
                        this.coworkerService.coworkerDelete.emit(this.coworker.id);
                        this.snackbarService.openSnackbarWithMessage('coworkers.coworker-deleted');
                        this.dialogRef.close(this.coworker);
                    });
                }
            });
    }

    onCancel() {
        this.clearForm();
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.coworkerForm.get('intern').value === true) {
            const totalHolidaysControl = this.coworkerForm.get('totalHolidays');
            const remainingHolidaysControl = this.coworkerForm.get('remainingHolidays');

            totalHolidaysControl.clearValidators();
            remainingHolidaysControl.clearValidators();
            totalHolidaysControl.updateValueAndValidity();
            remainingHolidaysControl.updateValueAndValidity();
        }

        if (!this.formIsValid()) {
            if (!this.name.valid) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-name');
            } else if (!this.email.valid) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-email');
            } else if (!this.role.valid) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-role');
            } else if (
                !this.fte.valid &&
                this.roles.find((role) => role.enumValue === Role.ENGINEER).id == this.role.value
            ) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-fte');
            } else if (!this.totalHolidays.valid) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-total-holidays');
            } else if (this.remainingHolidays.hasError('required')) {
                this.snackbarService.openSnackbarWithMessage('client.invalid-remaining-holidays');
            } else if (this.remainingHolidays.hasError('smallerThanOrEqual')) {
                this.snackbarService.openSnackbarWithMessage('client.remaining-holidays-is-bigger-than-total-holidays');
            } else {
                this.snackbarService.openSnackbarWithMessage('client.required-values');
            }
            return;
        }

        //Added job titles
        switch (this.role.value) {
        case 2:
            this.coworker.jobTitle = JobType.DEVELOPER;
            break;
        case 4:
            this.coworker.jobTitle = JobType.QA;
            break;
        case 1:
            this.coworker.jobTitle = JobType.PROJECT_MANAGER;
            break;
        default:
            this.coworker.jobTitle = null;
            break;
        }

        this.coworker.name = this.name.value;
        this.coworker.activated = this.active.value;
        this.coworker.email = this.email.value;
        this.coworker.role = this.role.value;
        this.coworker.fte = this.fte.value;
        this.coworker.intern = this.intern.value ?? false;
        this.coworker.calendarColor = this.intern.value ? this.calendarColor.value : '';

        if (this.coworker.intern) {
            this.coworker.totalHolidays = 0;
            this.coworker.remainingHolidays = 0;
        } else {
            this.coworker.totalHolidays = this.totalHolidays.value;
            this.coworker.remainingHolidays = this.remainingHolidays.value;
        }

        this.saving = true;

        (this.coworker.id
            ? this.coworkerService.updateCoworker(this.coworker)
            : this.coworkerService.inviteUser(this.coworker)
        ).subscribe({
            next: (coworker) => {
                if (coworker.id === this.tokenStorageService.getUserId()) {
                    this.tokenStorageService.updateUser(coworker);
                }

                this.saveFiles(coworker);
            },
            error: () => (this.saving = false),
        });
    }

    async saveFiles(coworker: Coworker) {
        if (!coworker) {
            this.snackbarService.openSnackbarWithMessage('client.required-values');
            return;
        }
        await this.coworkerService.saveProfilePic(this.profileImage, coworker.id);
        this.coworkerService.saveContracts(this.contracts, coworker.id);
        this.coworkerService.saveDocuments(this.otherDocuments, coworker.id);
        this.coworkerService.saveStatements(this.statements, coworker.id);
        this.coworkerService.saveLabourDocuments(this.documents, coworker.id);

        this.removedDocuments.forEach((i) => this.coworkerService.deleteDocument(i.id));

        this.clearForm();
        this.coworkerSave.emit(coworker);
        this.snackbarService.openSnackbarWithMessage(this.translate.instant('coworkers.coworkerAdded'));
        if (coworker.role !== Role.ADMIN) {
            this.dialogRef.close(coworker);
        } else {
            this.dialogRef.close();
        }
    }

    downloadOldFile(document: Document) {
        this.coworkerService.downloadDocument('/coworker/document/', document.id, document.originalName);
    }

    downloadNewFile(document: File) {
        this.fileService.save(document, document.name);
    }

    acceptTypes(): string {
        return getAcceptImageTypes();
    }

    isRoleWithFteSelected() {
        const selectedRoleId = this.role.value;
        const isFteNeededForRole = this.roles.some(
            (role) => this.rolesWithFte.includes(role.enumValue) && role.id === selectedRoleId,
        );
        if (!isFteNeededForRole) {
            this.fte.setValue(null);
        }
        return isFteNeededForRole;
    }

    isRoleInternshipAvailable() {
        const selectedRoleId = this.role.value;
        const isAvailable = this.roles.some(
            (role) => this.rolesWithInternship.includes(role.enumValue) && role.id === selectedRoleId,
        );

        if (!isAvailable) {
            this.coworkerForm.get('intern').setValue(false);
        }

        return isAvailable;
    }

    initHolidayDropdowns() {
        for (let i = 1; i <= 50; i++) {
            this.holidayOptions.push(new DropdownItem(i, i.toString()));
        }
    }
}
