<div>
    <mat-form-field appearance="fill"  [formGroup]='searchForm'>
        <input
            class="dontHideResults"
            required="{{ required }}"
            formControlName="searchString"
            autocomplete="off"
            [value]="inputValue"
            [attr.disabled]="disabled"
            [maxLength]="maxLength"
            matInput
            [placeholder]="'coworkers.search-by-name' | translate"
            (keyup.enter)="handleSearch()"
        />
        <button matSuffix (click)="handleSearch()">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <div class="results dontHideResults" *ngIf="showResults">
        <div class="quickSearchResult dontHideResults" *ngFor="let result of resultValues">
            <p class="clickableResult" (click)="handleSearch(result)">{{ result }}</p>
        </div>
    </div>
</div>
