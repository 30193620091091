import { Component, Input, OnInit } from '@angular/core';
import { WorklogTimesheet } from '../../../../model/worklog-timesheet.model';

@Component({
    selector: 'app-timesheet-dialog-details',
    templateUrl: './timesheet-dialog-details.component.html',
    styleUrls: ['./timesheet-dialog-details.component.scss'],
})
export class TimesheetDialogDetailsComponent {
    @Input() worklogs: WorklogTimesheet[];
    @Input() projectName: string;
}
