import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DocumentsComponent } from './documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { FolderModule } from './folder/folder.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from '@app/material.module';

@NgModule({
    declarations: [DocumentsComponent],

    imports: [
        CommonModule,
        MaterialModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        FolderModule,
        DocumentsRoutingModule,
    ],
})
export class DocumentsModule {}
