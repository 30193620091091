import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FolderComponent } from './folder.component';

@NgModule({
    declarations: [FolderComponent],
    imports: [MatIconModule, CommonModule],
    exports: [FolderComponent],
})
export class FolderModule {}
