import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { NewFolderDialogComponent } from '../dialogs/document-dialogs/new-folder-dialog/new-folder-dialog.component';
import { Folder } from 'src/app/model/folder.model';
import { DocumentService } from 'src/app/service/document.service';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { SHOWN_IN_PHASE_TWO } from '@app/constants/constants';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
    folders: Folder[] = [];

    constructor(
        private dialog: MatDialog,
        private documentService: DocumentService,
        public translate: TranslateService,
        public tokenService: TokenStorageService,
    ) {}

    ngOnInit(): void {
        this.documentService.getAllFolders().subscribe((foldersResp) => (this.folders = foldersResp));
    }

    creatrFolder() {
        this.dialog
            .open(NewFolderDialogComponent, {
                data: {},
                panelClass: 'new-folder-dialog',
            })
            .afterClosed()
            .subscribe(this.refreshFolders.bind(this));
    }
    refreshFolders() {
        this.documentService.getAllFolders().subscribe((foldersResp) => (this.folders = foldersResp));
    }

    protected readonly SHOWN_IN_PHASE_TWO = SHOWN_IN_PHASE_TWO;
}
