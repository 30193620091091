import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { InputComponent } from './input.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, TranslateModule, MatInputModule, MatIconModule],
    declarations: [InputComponent],
    exports: [InputComponent],
})
export class InputModule {}
