<mat-form-field appearance="fill">
    <mat-label class="label">{{ label }}</mat-label>
    <mat-select
        required="{{ required }}"
        [(value)]="selectedOptions"
        (selectionChange)="onSelectChange($event)"
        multiple
    >
        <mat-option *ngIf="selectOption" value="">{{ 'globalconstant.select-option' | translate }}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option.id">
            {{ option.name }}
        </mat-option>
    </mat-select>
    <mat-error class="error" *ngIf="hasError">{{ errorMessage }}</mat-error>
</mat-form-field>
