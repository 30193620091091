import {
    AbstractControl,
    ControlValueAccessor,
    ValidationErrors,
    ValidatorFn,
    Validators,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { convertDurationStringToMin } from '@converter/duration-converter';

function isLargerOrEqualsMin(minDurationInMin: number, durationInMin: number): boolean {
    return !isNaN(durationInMin) && durationInMin >= minDurationInMin;
}

function isSmallerOrEqualsMax(maxDurationInMin: number, durationInMin: number): boolean {
    return !isNaN(durationInMin) && durationInMin <= maxDurationInMin;
}

export function minDuration(min: string): ValidatorFn {
    let minDurationInMin = convertDurationStringToMin(min);

    return (control: AbstractControl): ValidationErrors | null => {
        let validationErrors = null;

        if (control.value) {
            if (!isLargerOrEqualsMin(minDurationInMin, convertDurationStringToMin(control.value))) {
                validationErrors = {
                    min: {
                        min: min,
                        actual: control.value,
                    },
                };
            }
        }
        return validationErrors;
    };
}

export function maxDuration(max: string): ValidatorFn {
    let maxDurationInMin = convertDurationStringToMin(max);

    return (control: AbstractControl): ValidationErrors | null => {
        let validationErrors = null;

        if (control.value) {
            if (!isSmallerOrEqualsMax(maxDurationInMin, convertDurationStringToMin(control.value))) {
                validationErrors = {
                    max: {
                        max: max,
                        actual: control.value,
                    },
                };
            }
        }
        return validationErrors;
    };
}
