import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from '../view/notification/notification.component';
import { AlertType } from '../enum/alert-type.enum';
import { Alert } from '../model/alert.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationSystemService {
    constructor(public dialog: MatDialog) {}

    success(message: string, title?: string) {
        this.openDialog(AlertType.SUCCESS, message, title);
    }

    error(message: string, title?: string) {
        this.openDialog(AlertType.ERROR, message, title);
    }

    warning(message: string, title?: string) {
        this.openDialog(AlertType.WARNING, message, title);
    }

    info(message: string, title?: string) {
        this.openDialog(AlertType.INFO, message, title);
    }

    openDialog(type: AlertType, message: string, title?: string) {
        this.dialog.open(NotificationComponent, {
            position: { top: '5%' },
            width: '35%',
            data: new Alert({
                type,
                title,
                message,
            }),
            disableClose: true,
            autoFocus: false,
        });
    }
}
