import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-stroked-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'stroked-button.component.html',
    styleUrls: ['stroked-button.component.scss'],
})
export class StrokedButtonComponent {
    @Input() title: string;
    @Input() disabled: boolean = false;
    @Output() onButtonClick = new EventEmitter<any>();

    onClick = () => this.onButtonClick.emit();
}
