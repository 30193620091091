import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar-confirmation',
    templateUrl: './snackbar-confirmation.component.html',
    styleUrls: ['./snackbar-confirmation.component.scss'],
})
export class SnackbarConfirmationComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackbarConfirmationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
    ) {}

    onCancel() {
        this.snackBarRef.dismiss();
    }

    onAction() {
        this.snackBarRef.dismissWithAction();
    }
}
