import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';

import { WorklogService } from '../../../service/worklog.service';
import { WorklogTimesheet } from '../../../model/worklog-timesheet.model';
import { TimesheetCoworkerProject } from '../../../model/timesheet-coworker-project.model';
import { FileService } from '@app/service/file-service';

@Component({
    selector: 'app-timesheet-details',
    templateUrl: './timesheet-details-dialog.component.html',
    styleUrls: ['./timesheet-details-dialog.component.scss'],
})
export class TimesheetDetailsDialogComponent implements OnInit {
    activeButton: number = 0;
    intervalButtons = [
        {
            name: this.translate.instant('timesheet.this-week'),
            id: 'this-week',
        },
        {
            name: this.translate.instant('timesheet.current-sprint'),
            id: 'current-sprint',
        },
        {
            name: this.translate.instant('timesheet.previous-sprint'),
            id: 'previous-sprint',
        },
        {
            name: this.translate.instant('timesheet.all-sprint'),
            id: 'all-sprint',
        },
    ];
    worklogs: WorklogTimesheet[];
    filteredWorklogs: WorklogTimesheet[] = [];

    constructor(
        public dialogRef: MatDialogRef<TimesheetDetailsDialogComponent>,
        private translate: TranslateService,
        private worklogService: WorklogService,
        @Inject(MAT_DIALOG_DATA) public project: TimesheetCoworkerProject,
        private fileService: FileService,
    ) {}

    ngOnInit(): void {
        this.worklogService.getWorklogsByCoworker(this.project.coworker.id).subscribe((res) => {
            this.worklogs = res;
            this.doFilter(this.intervalButtons[0]);
        });
    }

    navChange(selectedNumber: number) {
        this.activeButton = selectedNumber;
    }

    sumHours() {
        let hours: number = 0;
        let minutes: number = 0;

        this.filteredWorklogs.forEach((worklog) => {
            if (this.activeButton !== 0 || worklog.projectName === this.project.name) {
                worklog.time += '';
                const hoursAndMinute = worklog.time.split(':');
                hours += +hoursAndMinute[0];
                if (hoursAndMinute[1]) {
                    minutes += +hoursAndMinute[1];
                } else {
                    minutes = 0;
                }
            }
        });

        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        const hoursStr = hours < 10 ? '0' + hours : '' + hours;
        const minutesStr = minutes < 10 ? '0' + minutes : '' + minutes;
        return hoursStr + ':' + minutesStr;
    }

    doFilter(action: any) {
        switch (action.id) {
        case 'all-sprint':
            this.filteredWorklogs = this.worklogs;
            break;
        case 'this-week':
            this.filteredWorklogs = this.worklogs.filter((w) => w.thisWeek);
            break;
        }
    }

    detailsFilter(): WorklogTimesheet[] {
        return this.filteredWorklogs.filter((w) => w.projectName === this.project.name);
    }

    downloadExportedWorklogs() {
        this.worklogService
            .downloadExportedWorklogs(this.project.coworker.id, this.project.id)
            .subscribe((resp: HttpResponse<Blob>) => {
                const contentDispositionHeaderFileNameParts = resp.headers
                    .get('content-disposition')
                    .split('filename=');

                this.fileService.save(
                    resp.body,
                    contentDispositionHeaderFileNameParts.length && contentDispositionHeaderFileNameParts[1]
                        ? contentDispositionHeaderFileNameParts[1].split('"').join('')
                        : 'worklog.xls',
                );
            });
    }
}
