import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HolidayService } from '@app/service/holiday.service';

@Component({
    selector: 'app-confirmation-dialog-with-message',
    templateUrl: './confirmation-dialog-with-message.component.html',
    styleUrls: ['./confirmation-dialog-with-message.component.scss'],
})
export class ConfirmationDialogWithMessageComponent {
    MAX_MESSAGE_LENGTH = 500;
    reasoning: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<ConfirmationDialogWithMessageComponent>,
        public holidayService: HolidayService,
    ) {}

    close(value: boolean) {
        this.matDialogRef.close({ value });
    }

    handleButtonClick() {
        this.close(true);
        this.holidayService.setReasoningMessage(this.reasoning);
    }

    canBeSent(): boolean {
        return this.reasoning.length > 0 && this.reasoning.length <= this.MAX_MESSAGE_LENGTH;
    }
}
