import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { JobTitleTranslationKeys } from 'src/app/constants/constants';
import { Folder } from 'src/app/model/folder.model';
import { CoworkerService } from 'src/app/service/coworker.service';
import { DocumentService } from 'src/app/service/document.service';
import { FormBuilderService } from 'src/app/service/form-builder.service';

@Component({
    selector: 'new-folder-dialog',
    templateUrl: './new-folder-dialog.component.html',
    styleUrls: ['./new-folder-dialog.component.scss'],
})
export class NewFolderDialogComponent {
    folderForm: UntypedFormGroup;
    folder: Folder = new Folder();
    folderPermissions = [];
    @Output() refreshFolders = new EventEmitter<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Folder,
        private formBuilderService: FormBuilderService,
        private coworkerService: CoworkerService,
        private translate: TranslateService,
        private documentService: DocumentService,
        public matDialogRef: MatDialogRef<NewFolderDialogComponent>,
    ) {
        this.folder = data;
        this.folderForm = this.formBuilderService.createFolderForm(this.folder);
        this.getJobTypes();
    }

    onSubmit() {
        this.folderForm.markAllAsTouched();
        this.folder = this.folderForm.value;
        (this.folder.id
            ? this.documentService.updateFolder(this.folder)
            : this.documentService.saveFolder(this.folder)
        ).subscribe((folder) => this.matDialogRef.close(folder));
        this.refreshFolders.emit();
    }

    async getJobTypes() {
        return this.coworkerService.getJobTitles().subscribe((resp: string[]) => {
            this.folderPermissions = resp.map((jobType, index) => ({
                jobType,
                name: this.translate.instant(JobTitleTranslationKeys[jobType]),
                id: index,
            }));

            if (this.folder.folderPermissions) {
                this.folderForm
                    .get('folderPermissions')
                    .setValue(
                        this.folder.folderPermissions.map(
                            (fp) => this.folderPermissions.find((i) => i.jobType === fp).id,
                        ),
                    );
            }
        });
    }
}
