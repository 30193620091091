import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Folder } from '../model/folder.model';
import { SharedDocument } from '../model/shared-document.model';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    // tslint:disable-next-line:deprecation
    constructor(private httpService: HttpService) {}

    saveFolder(folder: Folder): Observable<Folder> {
        return this.httpService.post('/folders', folder);
    }

    updateFolder(folder: Folder): Observable<Folder> {
        return this.httpService.patch(`/folders/${folder.id}`, folder);
    }

    getAllFolders(): Observable<Folder[]> {
        return this.httpService.get('/folders');
    }

    deleteFolder(id: number): Observable<any> {
        return this.httpService.delete(`/folders/${id}`);
    }

    saveSharedDocument(document: SharedDocument, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('id', document.id ? document.id + '' : '0');
        formData.append('name', document.name);
        formData.append('folderId', document.folderId + '');
        if (document.oneTimeOpenPermission) {
            document.oneTimeOpenPermission.forEach((permission) => {
                formData.append('oneTimeOpenPermission', permission);
            });
        }
        return this.httpService.post('/folders/documents', formData, false);
    }

    getDocumentsByFolderId(id: number): Observable<SharedDocument[]> {
        return this.httpService.get(`/folders/${id}/documents`);
    }

    getFolderById(id: number): Observable<Folder> {
        return this.httpService.get(`/folders/${id}`);
    }

    getFileByDocumentId(id: number): Observable<any> {
        return this.httpService.getFile(`/folders/documents/${id}/file`);
    }

    deleteDocument(id: number): Observable<any> {
        return this.httpService.delete(`/folders/documents/${id}`);
    }
}
