export enum CalendarColor {
    SUN_YELLOW = '#FCC010',
    ORANGE = '#F4971B',
    BRIGHT_RED = '#F9473A',
    WINE_RED = '#CD2E55',
    PINK = '#F6BCD0',
    GREY = '#DEDCDD',
    GREYISH_GREEN = '#9DBFAE',
    GREEN = '#8DC04E',
    FOREST_GREEN = '#13955F',
    DARK_GREEN = '#335F4B',
    STEEL_GREY = '#627E88',
    DARK_BLUE = '#4153A1',
    INTERN_BLUE = '#438ECC',
    TURQUOISE = '#1EB8D1',
    LAKE_GREEN = '#088EA7',
}
