import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrokedBlueButtonComponent } from './stroked-blue-button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [StrokedBlueButtonComponent],
    exports: [StrokedBlueButtonComponent],
})
export class StrokedBlueButtonModule {}
