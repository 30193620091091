import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-multi-select',
    templateUrl: 'multi-select.component.html',
    styleUrls: ['multi-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true,
        },
    ],
})
export class MultiSelectComponent implements ControlValueAccessor {
    @Input() formControlName: string;
    @Input() options = [];
    @Input() label: string;
    @Input() required = false;
    @Input() hasError = false;
    @Input() selectOption = true;
    @Input() errorMessage = '';
    @Output() valueChange = new EventEmitter<any>();
    selectedOptions = [];

    writeValue(values: any) {
        this.selectedOptions = values;
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touch fn
    }

    onSelectChange({ value }: MatSelectChange) {
        this.selectedOptions = value;
        if (this.propagateChange) {
            this.propagateChange(this.selectedOptions);
        }
        this.valueChange.emit(this.selectedOptions);
    }
}
