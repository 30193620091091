<div class="dialog">
    <div class="title">{{ (folder.id ? 'documents.edit-folder' : 'documents.new-folder') | translate }}</div>
    <form [formGroup]="folderForm">
        <div>
            <app-input [required]="true" [label]="'documents.folder-name' | translate" formControlName="name">
            </app-input>
        </div>
        <div>
            <app-multi-select
                [selectOption]="false"
                [required]="true"
                [label]="'documents.folder-access' | translate"
                formControlName="folderPermissions"
                [options]="folderPermissions"
            ></app-multi-select>
        </div>
        <div class="d-flex justify-content-between buttons">
            <span class="button-container">
                <app-stroked-button [title]="'globalconstant.cancel' | translate" [mat-dialog-close]="true">
                </app-stroked-button>
            </span>
            <span class="button-container">
                <app-blue-button (onButtonClick)="onSubmit()" [title]="'globalconstant.save' | translate">
                </app-blue-button>
            </span>
        </div>
    </form>
</div>
