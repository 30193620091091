import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, SelectControlValueAccessor } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-select',
    templateUrl: 'select.component.html',
    styleUrls: ['select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
    @Input() formControlName: string;
    @Input() options = [];
    @Input() label: string;
    @Input() fontColor: string = 'dark';
    @Input() required = false;
    @Input() hasError = false;
    @Input() errorMessage = '';
    @Input() hiddenSelectOption = true;
    @Output() valueChange = new EventEmitter<any>();
    @Input() disabled: boolean;
    @Input() selectedOption = 'default';

    ngOnInit(): void {
        this.valueChange.emit(this.selectedOption);
    }

    writeValue(value: any) {
        this.selectedOption = value;
        this.valueChange.emit(this.selectedOption);
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touch fn
    }

    onSelectChange({ value }: MatSelectChange) {
        this.selectedOption = value;
        if (this.propagateChange) {
            this.propagateChange(this.selectedOption);
        }
        this.valueChange.emit(this.selectedOption);
    }
}
