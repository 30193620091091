import { Injectable } from '@angular/core';
import { Observable, debounceTime, fromEvent, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    isScrolledToBottom(treshold: number = 0): Observable<boolean> {
        return fromEvent(window, 'scroll').pipe(
            debounceTime(500),
            map(() => this.checkIfScrolledToBottom(treshold)),
        );
    }

    private checkIfScrolledToBottom(treshold: number): boolean {
        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
        const docHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
        );

        return window.scrollY + windowHeight >= docHeight - treshold;
    }
}
