import { Component, Input, OnChanges } from '@angular/core';
import { WorklogTimesheet } from '../../../../model/worklog-timesheet.model';

@Component({
    selector: 'app-timesheet-dialog-timesheet',
    templateUrl: './timesheet-dialog-timesheet.component.html',
    styleUrls: ['./timesheet-dialog-timesheet.component.scss'],
})
export class TimesheetDialogTimesheetComponent implements OnChanges {
    @Input() worklogs: WorklogTimesheet[];
    timesheet: WorklogTimesheet[] = [];

    ngOnChanges() {
        this.initWorklogs();
    }

    initWorklogs() {
        this.timesheet = this.worklogs.slice();
        this.timesheet = this.timesheet.sort((a, b) =>
            a.projectName > b.projectName ? 1 : b.projectName > a.projectName ? -1 : 0,
        );
    }

    getRowspanValue(i) {
        let count: number = 1;
        for (
            let j = i + 1;
            j < this.timesheet.length && this.timesheet[j].projectName === this.timesheet[i].projectName;
            j++
        ) {
            count++;
        }
        return count;
    }
}
