<table>
    <tr *ngFor="let worklog of timesheet; index as i">
        <td
            *ngIf="i < 1 || this.timesheet[i].projectName !== this.timesheet[i - 1].projectName"
            [attr.rowspan]="getRowspanValue(i)"
            class="project-col"
        >
            {{ timesheet[i].projectName }}
        </td>
        <td>{{ timesheet[i].task }}</td>
        <td class="last-col">{{ timesheet[i].time }}</td>
    </tr>
</table>
