export const SIMPLE_DATE_FORMAT = 'yyyy.MM.dd';
export const MONTH_FORMAT = 'MMMM';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const HOUR_MINUTE_FORMAT = 'H:mm';
export const HOUR_FORMAT = 'HH';
export const MINUTE_FORMAT = 'mm';
export const DEFAULT_PROFILE_PICTURE_PATH = './assets/default.png';
export const DEFAULT_PROJECT_PICTURE_PATH = './assets/defaultproject.png';
export const RIGHT_ARROW_PATH = './assets/arrow-right.svg';
export const PROJECT_TYPE = 'project';
export const COWORKER_TYPE = 'coworker';

/*
  Some parts of the UI need to be hidden in phase one.
  This boolean is used in *ngIf directives on those
  components to conditionally show/hide them as required.
*/
export const SHOWN_IN_PHASE_ONE = false;

/*
  Some parts of the UI need to be hidden in phase two.
  This boolean is used in *ngIf directives on those
  components to conditionally show/hide them as required.
*/
export const SHOWN_IN_PHASE_TWO = false;

export const RoleTranslationKeys = {
    PROJECT_MANAGER: 'roles.project-manager',
    HR: 'roles.hr',
    ADMIN: 'roles.admin',
    CLIENT: 'roles.client',
    ENGINEER: 'roles.engineer',
    QA: 'roles.qa',
};

export const JobTitleTranslationKeys = {
    DEVELOPER: 'job-titles.developer',
    TESTER: 'job-titles.tester',
    PROJECT_MANAGER: 'job-titles.project-manager',
    HR: 'job-titles.hr',
};

export const PaymentPeriodTranslationKeys = {
    MONTHLY: 'payment-period.monthly',
    PROJECT: 'payment-period.project',
    SPRINT: 'payment-period.sprint',
};

export const LanguageTranslationKeys = {
    HUNGARIAN: 'languages.hungarian',
    ENGLISH: 'languages.english',
    GERMAN: 'languages.german',
};
