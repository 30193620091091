import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { NotificationComponent } from './notification.component';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationSystemService } from 'src/app/service/notification-system.service';

@NgModule({
    declarations: [NotificationComponent],
    exports: [NotificationComponent],
    imports: [CommonModule, MatIconModule, MatDialogModule, TranslateModule],
    providers: [NotificationSystemService],
})
export class NotificationModule {}
