import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackbarService } from 'src/app/service/snackbar.service';
import { TokenStorageService } from '@app/service/token-storage.service';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
    constructor(
        private snackbarService: SnackbarService,
        private tokenService: TokenStorageService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request.headers.set('Authorization', 'Bearer ' + this.tokenService.getToken());
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status !== 401) {
                        this.snackbarService.openSnackbarWithMessage(
                            error.error && error.error.message ? error.error.message : 'error.500',
                        );
                    } else if (error.url.includes('login/oauth2')) {
                        this.snackbarService.openSnackbarWithMessage('error.USER_NOT_FOUND');
                    }
                }
                return throwError(error);
            }),
        );
    }
}
