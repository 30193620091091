import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Coworker } from '@model/coworker.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SharedService } from '@app/service/shared.service';

@Component({
    selector: 'app-coworker-list',
    templateUrl: './coworker-list.component.html',
    styleUrls: ['./coworker-list.component.scss'],
})
export class CoworkerListComponent implements OnChanges, OnInit {
    @Output() selectedCoworker: EventEmitter<Coworker> = new EventEmitter();
    @Output() selectedActivatedItem: EventEmitter<{ name: string; id: string }> = new EventEmitter();
    @Output() tabSelectionChange = new EventEmitter<string>();
    @Input() coworkers: Coworker[];
    @Input() searchActive: boolean;
    @Input() isSearching: boolean;
    receivedFlag: boolean = false;

    activeButtons = [
        {
            name: this.translate.instant('coworkers.active'),
            id: 'ACTIVE',
            color: 'activeColor',
        },
        {
            name: this.translate.instant('coworkers.not-active'),
            id: 'NOT_ACTIVE',
            color: 'inactiveColor',
        },
    ];

    constructor(
        public translate: TranslateService,
        private changeDetector: ChangeDetectorRef,
        private sharedService: SharedService,
    ) {}

    ngOnInit(): void {
        this.sharedService.flag$.subscribe((flag) => {
            this.receivedFlag = flag;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changeDetector.detectChanges();
    }

    editCoworker(coworker: Coworker) {
        this.selectedCoworker.emit(coworker);
    }

    onActiveChange(button) {
        this.selectedActivatedItem.emit(button);
    }

    onTabSelectionChange(event: MatTabChangeEvent): void {
        const selectedTabLabel = event.tab.textLabel;
        this.tabSelectionChange.emit(selectedTabLabel);
    }
}
