import { HttpService } from './http.service';
import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HolidayRequest } from '@app/model/holiday-request.model';
import HolidayStatus from '@app/model/holiday-status.model';
import { HolidayResponse } from '@app/model/holiday-response.model';
import Holiday from '@app/model/holiday.model';
import { CalendarDay } from '@app/model/calendar-day.model';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HolidayState } from '@app/enum/holiday-state.enum';

@Injectable({
    providedIn: 'root',
})
export class HolidayService {
    @Output()
    private selectedDaysSubject = new BehaviorSubject<CalendarDay[]>([]);
    selectedDays$ = this.selectedDaysSubject.asObservable();
    private refreshRequest = new BehaviorSubject<void>(null);
    refreshRequest$ = this.refreshRequest.asObservable();
    nationalHolidays: string[] = [];
    holidaysOfUser: HolidayStatus[] = [];
    previousYear: number = null;
    reasoningMessage: string = '';

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private tokenStorageService: TokenStorageService,
    ) {}

    async getHolidays(year: number): Promise<string[]> {
        if (this.previousYear != year) {
            this.previousYear = year;
            this.nationalHolidays = await this.getNationalHolidays(year);
        }

        return this.nationalHolidays;
    }

    async getAllHolidays(): Promise<HolidayStatus[]> {
        this.holidaysOfUser = await this.getAllHolidaysOfUser();
        this.holidaysOfUser = this.holidaysOfUser.map(({ startDay, endDay, state }) => {
            return { startDay, endDay, state };
        });

        return this.holidaysOfUser;
    }

    async getNationalHolidays(year: number): Promise<string[]> {
        const response = await this.httpService.get(`/holidays/national-holidays?year=${year}`).toPromise();

        return response as string[];
    }

    refreshCalendar() {
        this.refreshRequest.next();
    }

    async getAllHolidaysOfUser(): Promise<HolidayStatus[]> {
        const userId: number = this.tokenStorageService.getUserId();
        const response = await this.httpService.get(`/holidays/${userId}`).toPromise();

        return response as HolidayStatus[];
    }

    getAllHolidaysByUserId() {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/holidays/${userId}`);
    }

    getAllPendingHolidaysByUserId() {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/holidays/pending-holidays/${userId}`);
    }

    getNumberOfRemainingHolidaysById(): Observable<number> {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/coworker/remaining/${userId}`);
    }

    isNationalHoliday(date: Date): boolean {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return this.nationalHolidays.includes(formattedDate);
    }

    getPendingHolidays(): Observable<number> {
        return this.httpService.get('/holidays/pending');
    }

    getLastFiveApproved(): Observable<Holiday[]> {
        return this.httpService.get('/holidays/last-approved');
    }

    getAllApprovedHolidaysInRange(startDate: string, endDate: string): Observable<Holiday[]> {
        const params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

        return this.http.get<Holiday[]>(`${environment.serverUrl}/holidays/approved-holiday-in-range`, { params });
    }

    getPendingHolidaysByUserId(id: number): Observable<number> {
        return this.httpService.get('/holidays/pending/' + id);
    }

    getAllApprovedHolidaysByUserId(): Observable<Holiday[]> {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/holidays/approved-holidays/${userId}`);
    }

    getApprovedHolidaysByUserId(id: number): Observable<number> {
        return this.httpService.get('/holidays/approved/' + id);
    }

    getAllHolidaysByStatus(status: string) {
        return this.httpService.get(`/holidays/all/${status}`);
    }

    getHolidayById(id: number) {
        return this.httpService.get(`/holidays/single/${id}`);
    }

    updateSelectedDays(newSelectedDays: CalendarDay[]) {
        this.selectedDaysSubject.next(newSelectedDays);
        const subscription = this.selectedDaysSubject.subscribe((value: CalendarDay[]) => {
            value.forEach((val) => {
                const date = new Date(val.date);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const formattedDate = `${year}.${month}.${day}`;

                return (val.date = formattedDate);
            });
        });
        subscription.unsubscribe();
    }

    convertToJSDate(dateString: string) {
        // YYYY.MM.DD => JS Date
        const parts = dateString.split('.');

        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1;
        const day = parseInt(parts[2]);

        return new Date(year, month, day);
    }

    formatDate(date: Date, separator: string) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}${separator}${month}${separator}${day}`;

        return formattedDate;
    }

    sendHolidayRequest(holidayRequest: HolidayRequest): Observable<HolidayRequest> {
        return this.httpService.post('/holidays', holidayRequest);
    }

    updateHoliday(holiday: Holiday): Observable<Holiday> {
        return this.httpService.patch('/holidays/' + holiday.id, holiday);
    }

    deleteHoliday(id: number) {
        return this.httpService.delete(`/holidays/${id}`);
    }

    setReasoningMessage(message: string) {
        this.reasoningMessage = message;
    }

    isApproved(date: Date | string, holidays: Holiday[]): boolean {
        let result = false;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');

            holidays.forEach((holiday) => {
                if (
                    this.isDateBetween(holiday.startDay, holiday.endDay, formattedDate) &&
                    holiday.state === HolidayState.APPROVED
                ) {
                    result = true;
                }
            });
        }

        return result;
    }

    isDeclined(date: Date | string, holidays: Holiday[]): boolean {
        let result = false;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');

            holidays.forEach((holiday) => {
                if (
                    this.isDateBetween(holiday.startDay, holiday.endDay, formattedDate) &&
                    holiday.state === HolidayState.DECLINED
                ) {
                    result = true;
                }
            });
        }

        return result;
    }

    isPending(date: Date | string, holidays: Holiday[]): boolean {
        let result = false;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');

            holidays.forEach((holiday) => {
                if (
                    this.isDateBetween(holiday.startDay, holiday.endDay, formattedDate) &&
                    holiday.state === HolidayState.PENDING
                ) {
                    result = true;
                }
            });
        }

        return result;
    }

    pendingDate(date: Date | string, holidays: Holiday[]): Holiday {
        let result = null;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');
            holidays.forEach((holiday) => {
                if (
                    this.isDateBetween(holiday.startDay, holiday.endDay, formattedDate) &&
                    holiday.state === HolidayState.PENDING
                ) {
                    result = holiday;
                }
            });
        }
        return result;
    }

    approvedDate(date: Date | string, holidays: Holiday[]): Holiday {
        let result = null;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');
            holidays.forEach((holiday) => {
                if (
                    this.isDateBetween(holiday.startDay, holiday.endDay, formattedDate) &&
                    holiday.state === HolidayState.APPROVED
                ) {
                    result = holiday;
                }
            });
        }
        return result;
    }

    isDateBetween(startDate: string, endDate: string, dateToCheck: string): boolean {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const dateToCheckObj = new Date(dateToCheck);

        return startDateObj <= dateToCheckObj && dateToCheckObj <= endDateObj;
    }
}
