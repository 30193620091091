import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarConfirmationComponent } from '../shared/snackbar-confirmation/snackbar-confirmation.component';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(
        private translate: TranslateService,
        private snackbar: MatSnackBar,
    ) {}

    openSnackbarWithMessage(message: string, params?: object) {
        this.translate.get(message, params).subscribe((text) => {
            this.snackbar.open(text, this.translate.instant('error.close'), {
                duration: 6000,
            });
        });
    }

    openConfirmationSnackbar(actionKey: string, cancelKey: string, messageKey: string, params?: object) {
        return this.snackbar.openFromComponent(SnackbarConfirmationComponent, {
            data: {
                action: this.translate.instant(actionKey),
                cancel: this.translate.instant(cancelKey),
                message: this.translate.instant(messageKey, params),
            },
        });
    }
}
