import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { JobTitleTranslationKeys } from 'src/app/constants/constants';
import { Folder } from 'src/app/model/folder.model';
import { SharedDocument } from 'src/app/model/shared-document.model';
import { DocumentService } from 'src/app/service/document.service';
import { FormBuilderService } from 'src/app/service/form-builder.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
    selector: 'new-document-dialog',
    templateUrl: './new-document-dialog.component.html',
    styleUrls: ['./new-document-dialog.component.scss'],
})
export class NewDocumentDialogComponent implements OnInit {
    oneTimePermissions = [];
    documentForm: UntypedFormGroup;
    document: SharedDocument;
    file: File;
    folder: Folder;

    constructor(
        private translate: TranslateService,
        private formBuilderService: FormBuilderService,
        private documentService: DocumentService,
        public dialogRef: MatDialogRef<NewDocumentDialogComponent>,
        private snackbarService: SnackbarService,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.folder = data.folder;
        this.document = data.document ? data.document : new SharedDocument();
        this.document.folderId = this.folder.id;
        if (this.document.id) {
            this.loadFile();
        }
    }

    ngOnInit(): void {
        this.getJobTypes();
        this.documentForm = this.formBuilderService.createSharedDocumentForm(this.document);
    }

    documentUpload(event) {
        const file: File = event.target.files[0];
        if (file) {
            this.file = file;
        }
    }

    onSubmit() {
        this.documentForm.markAllAsTouched();
        this.document = this.documentForm.value;

        this.documentForm.valid && this.file
            ? this.documentService.saveSharedDocument(this.document, this.file).subscribe(() => this.dialogRef.close())
            : this.snackbarService.openSnackbarWithMessage('documents.required-values');
    }

    getJobTypes() {
        this.oneTimePermissions = this.folder.folderPermissions.map((jobType) => ({
            name: this.translate.instant(JobTitleTranslationKeys[jobType]),
            id: jobType,
        }));
    }

    loadFile() {
        this.documentService.getFileByDocumentId(this.document.id).subscribe((resp) => {
            this.file = new File([resp], this.document.originalName, { type: this.document.contentType });
        });
    }
}
