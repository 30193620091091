import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from 'src/app/model/alert.model';
import { AlertType } from 'src/app/enum/alert-type.enum';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
    screenWidth: number;

    color: string;
    bgColor: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: Alert,
        public dialogRef: MatDialogRef<NotificationComponent>,
    ) {
        this.screenWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: UIEvent) {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth > 992) {
            this.dialogRef.updateSize('35%');
        } else if (this.screenWidth < 992 && this.screenWidth > 576) {
            this.dialogRef.updateSize('50%');
        } else {
            this.dialogRef.updateSize('85%');
        }
    }

    ngOnInit() {
        switch (this.data.type) {
        case AlertType.SUCCESS:
            this.color = 'text-success';
            this.bgColor = 'bg-success';
            break;
        case AlertType.ERROR:
            this.color = 'text-danger';
            this.bgColor = 'bg-danger';
            break;
        case AlertType.WARNING:
            this.color = 'text-warning';
            this.bgColor = 'bg-warning';
            break;
        case AlertType.INFO:
            this.color = 'text-info';
            this.bgColor = 'bg-info';
            break;
        }
    }

    getAlertIcon() {
        switch (this.data.type) {
        case AlertType.SUCCESS:
            return 'check_circle_outline';
        case AlertType.ERROR:
            return 'warning';
        case AlertType.WARNING:
            return 'error_outline';
        case AlertType.INFO:
            return 'info';
        }
    }
}
